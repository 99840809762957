@mixin action-menu-component {
    .action-menu {
        display: block;
    }

    .action-menu__item {
        color: var(--foreground-secondary);
        text-decoration: none;
        appearance: none;
        border: 0;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: transitions-fast(background-color, color);
        font-size: font-size();
        line-height: font-size();

        &:hover {
            color: var(--foreground);
        }
    }

    .action-menu__panel {
        .action-menu__item {
            display: block;
            padding: spacing(x-small) spacing();
            color: var(--foreground);
            width: 100%;
            text-align: left;

            &:hover {
                background-color: var(--background-light-info);
            }

            &:focus {
                color: var(--foreground-link);
                background-color: var(--background-light-info);
            }
        }

        fa-icon {
            display: none;
        }
    }
}
