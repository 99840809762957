.user-details {
    .user-details__link {
        display: inline-block;
    }

    &__image {
        display: inline-block;
        width: 31px;
        margin: 7px 7px;
        vertical-align: middle;
        border-radius: 50%;

        @include break(tablet) {
            margin: 16px 11px;
        }
    }

    &__name {
        font-size: 0.8em;
        display: inline-block;
        color: #c5cde0;
    }

    &__position {
        display: none;
        color: lighten(#526fa3, 20%);
    }

    &__information {
        display: none;
    }
}

.site-menu {
    background: darken(#526fa3, 9%);
}

.site-menu-control {
    text-align: center;
}

.site-menu__link {
    display: block;
    transition: all 0.4s;
}

.site-menu__menu {
    position: relative;
    transition: all 0.4s;

    &:hover {
        background: darken(#526fa3, 13%);
    }
}

.site-menu__chevron-container {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    transition: all 0.4s;

    .site-menu__chevron {
        font-size: 0.6em;
        display: block;
        padding: 5px;
        cursor: pointer;
        transition: all 0.4s;
    }

    &:hover {
        background: darken(#526fa3, 9%);
    }
}

.site-menu__label {
    // font-size: 0.8em;
    display: block;
    padding: 13px 20px;
    transition: all 0.3s;
    text-decoration: none;
}

.site-menu__icon {
    margin: 0 10px 0 0;
}

.site-menu__menu--active {
    .site-menu__link {
        color: #fff;
        background: darken(#526fa3, 15%);
    }

    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 5px;
        content: ' ';
        background: #3a7ac1;
    }
}

.site-menu__menu--show {
    background: darken(#526fa3, 13%);
}

.menu-nav__item--show,
.site-menu__menu--show {
    > .menu-nav {
        display: block;
    }

    > .site-menu__chevron-container .site-menu__chevron {
        transform: rotate(180deg);
    }
}

.site-menu__menu--active,
.site-menu__menu--show {
    position: relative;
}

.site-menu-overlay {
    position: fixed;
    z-index: 977;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.menu-nav {
    display: none;
    padding: 10px 0;
    list-style: none;

    &--sub {
        padding: 5px 0;

        .menu-nav__label {
            font-size: 0.65em;
            padding: 8px 20px 8px 40px;
        }
    }

    .menu-nav__item--active {
        > .menu-nav__link > .menu-nav__label {
            color: #fff;
            background: darken(#526fa3, 14%);
        }
    }

    .menu-nav__item {
        position: relative;

        .site-menu__chevron-container {
            margin: 6px 10px;
        }
    }

    &__link {
        position: relative;
        display: block;

        &:hover {
            > .menu-nav__label {
                color: #fff;
                background: darken(#526fa3, 16%);
            }
        }
    }
}

.menu-nav__external {
    font-size: 0.9em;
    // font-size: 0.7em;
    position: absolute;
    top: 9px;
    right: 10px;
}

.menu-nav__label {
    font-size: 0.9em;
    display: block;
    padding: 8px 20px;
    transition: all 0.3s;
    text-decoration: none;
}

.user-details__notifications {
    font-size: 0.7em;
    padding: 6px;
    transition: color 0.2s;
    color: #2e4368;

    .user-details__notifications-bell {
        transition: color 0.2s;
        transform: translate3d(0, 0, 0);
        margin-left: 3px;
    }

    &:hover {
        background: lighten(#2e4368, 7%);
    }

    @include break(tablet) {
        line-height: 64px;
    }
}

.user-details__notifications--unread {
    color: #fff;

    .user-details__notifications-bell {
        animation: pulse 5s infinite;
        color: #e74c3c;
    }
}

.user-details__notifications--animate {
    .user-details__notifications-bell {
        animation: pulse 1.5s infinite;
    }
}

@include break(tablet) {
    .user-details {
        position: relative;
        float: none;
        background: darken(#526fa3, 6%);

        .user-details__link {
            display: block;
            height: 60px;
            padding: 10px;
            padding-right: 40px;

            &:hover {
                background: darken(#526fa3, 8%);
            }
        }

        &__image {
            float: left;
            width: 40px;
            margin: 0 auto;
        }

        &__information {
            display: block;
            margin-left: 50px;
        }

        &__name {
            font-size: 0.9em;
            display: block;
            overflow: hidden;
            padding: 5px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__position {
            font-size: 0.6em;
            font-weight: bold;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .user-details__notifications {
            line-height: inherit;
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
        }
    }

    .site-menu {
        padding-top: 30px;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.4);
    }

    50% {
        transform: scale(0.9);
    }

    80% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}
