@mixin break($point) {
    @if $point == mobile {
        @media screen and (min-width: 390px) {
            @content;
        }
    } @else if $point == tablet {
        @media screen and (min-width: 768px) {
            @content;
        }
    } @else if $point == large-tablet {
        @media screen and (min-width: 950px) {
            @content;
        }
    } @else if $point == desktop {
        @media screen and (min-width: 1025px) {
            @content;
        }
    } @else if $point == large-desktop {
        @media screen and (min-width: 1250px) {
            @content;
        }
    } @else {
        @media screen and ($point) {
            @content;
        }
    }
}
