$content-list-hover: rgba(64, 243, 248, 0.06);

.content-card {
    margin-bottom: 25px;
    word-break: normal;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 1px 2px #d8dbe3;
}

.content-card__body {
    padding: 15px 25px;
}

.content-card__body + .content-card__body {
    border-top: 1px solid mat.get-color-from-palette($my-app-foreground, dividers);
}

// TODO: this is incorrectly name
.content-card__body__heading {
    margin: 15px 0 20px;
}

.content-card__footer {
    padding: 15px 25px;
}

.content-card__header {
    display: flex;
    padding: 0 25px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    align-items: center;
    border-bottom: 1px solid mat.get-color-from-palette($my-app-foreground, dividers);
    background: transparent;
}

.content-card__heading {
    font-size: 16px;
    font-weight: bold;
    font-weight: normal;
    margin: 0;
    letter-spacing: 0.7px;
    align-self: center;
    flex-grow: 1;
    padding: 1.4em 0;

    &.content-card__heading--center {
        text-align: center;
    }

    &.content-card__heading--large {
        font-size: 26px;
        color: #47464c;
    }
}

.content-list__no-results {
    padding: 20px;
    text-align: center;
    // color: #91a0ad;
    // Taken from mat-pallete.
    color: rgba(0, 0, 0, 0.38);
}

.placeholder-drop {
    height: 45px;
    width: 100%;
    background-color: #ededed;
}

.content-list {
    .content-list__list {
        margin: 10px 0;
        list-style: none;
    }

    // TODO platform-ui override
    h3,
    h4,
    h5 {
        font-weight: normal;
    }
}

.content-list__item {
    display: block;
    padding: 14px 21px;
    background: #fff;
    border-bottom: 1px solid $accent_grey;
    position: relative;
}

.content-list__item--small {
    padding: 9px 14px;
}

.content-list__item--borderless {
    border: 0;
}

.content-list__item p {
    margin: 0;
}

.content-list__item--more {
    font-size: 12px;
    line-height: 24px;
    display: block;
    padding: 10px 25px;
    transition: all 0.3s;
    text-align: center;

    &:hover {
        background-color: #f9f9f9;
    }
}

.content-list__item--simple {
    padding: 10px 25px;
    border-bottom: 1px solid $accent_grey;
}

.content-list__item--draggable {
    cursor: move;
    box-sizing: border-box;
    cursor: move;
    background: white;
}

.content-list__item--selected {
    background: $content-list-hover;
}

.content-list__link {
    cursor: pointer;
    transition: all 0.4s;

    &:hover,
    &:focus {
        background: $content-list-hover;
        outline: none;
    }
}

.content-list__block-link {
    outline: none;

    &::before {
        transition: background 0.4s;
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    &:focus,
    &:hover {
        &::before {
            background: rgba(64, 243, 248, 0.06);
        }
    }
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.14),
        0 8px 10px 1px rgba(0, 0, 0, 0.034), 0 3px 14px 2px rgba(0, 0, 0, 0.08);
}

.content-list__details {
    // padding: 15px 24px;
}

.content-list__col {
    padding-top: 15px;
    padding-bottom: 15px;

    .content-list__col-title {
        font-size: 0.8em;
        margin: 0;
        padding: 0 0 7px;
        color: #777;
    }
}

.content-list__heading {
    margin: 0;
}

.content-list__sub-heading {
    margin: 2px 0;
}

.content-list__title {
    font-size: 1em;
    margin: 0;
    padding-top: 4px;
    padding-bottom: 4px;
}

.content-list__detail {
    padding-bottom: 6px;
}

.content-list__item--clickable {
    padding-right: 48px;

    &:hover,
    &:focus {
        .content-list__link-chevron {
            opacity: 1;
        }
    }
}

.content-list__link-chevron {
    display: block;
    font-size: 16px;
    margin-top: -12px;
    opacity: 0.4;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: opacity 0.4s;
}

.content-list__status {
    max-width: 150px;
    flex: 0 0 150px;
    padding: 15px 0;
    border-right: 1px solid lighten(#e7ecf3, 5%);

    &:last-child {
        border-right: 0;
    }
}

.content-list__status-label {
    font-size: 11px;
    margin-left: 30px;
    padding: 7px 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #f8f9fc;
}

.content-list__status-label-secondary {
    font-size: 11px;
    margin-left: 30px;
    padding: 7px 0 7px 8px;
}

.content-list__description {
    font-size: 12px;
    color: #7b828a;
}

.content-list__actions {
    transition: all 0.4s;
}

.content-list__actions--hidden {
    opacity: 0;
}

.content-list__link:hover {
    .content-list__check {
        border-color: lighten(#8e939c, 20%);
    }

    .content-list__check--checked {
        border-color: $info;
    }

    .content-list__actions--hidden {
        opacity: 1;
    }
}

.content-list__check {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 14px;
    transition: all 0.3s;
    border: 3px solid #f9f9f9;
    border-radius: 4px;
    align-self: center;

    &:after {
        position: absolute;
        top: 0;
        left: 4px;
        width: 5px;
        height: 11px;
        content: ' ';
        transition: all 0.3s;
        transform: rotate(45deg);
        opacity: 0;
        border: solid #fff;
        border-width: 0 2px 2px 0;
    }

    &.content-list__check--checked {
        border-color: $info;
        background: $info;

        &:after {
            opacity: 1;
        }
    }
}

.content-card__toolbar {
    flex: 0 0 auto;
    display: flex;

    > .content-card__toolbar-item {
        margin-right: 0.4em;
    }

    .btns {
        flex-wrap: nowrap;
    }
}

.content-list-toolbar {
    padding: 20px 15px;

    &__item {
        padding: 0 5px;

        &--label {
            font-size: 0.8em;
            line-height: 39px;
            padding: 0 10px;
        }
    }

    &__label {
        font-size: 12px;
        padding: 5px;
        color: #aaa;
    }

    .content-list-toolbar__input-icon {
        font-size: 0.7em;
        position: absolute;
        top: 13px;
        left: 11px;
    }
}

.content-card-table {
    table,
    tr {
        width: 100%;
    }

    th {
        font-weight: normal;
        color: var(--foreground-secondary);
    }

    th,
    td {
        padding: 0.8em 0.5em;
    }

    thead th {
        background: lighten(#d6deeb, 7%);
    }

    .content-card-table__row--last,
    tr:last-child {
        th,
        td {
            border-bottom: 0;
        }
    }

    .content-card-table__row--center {
        th,
        td {
            vertical-align: middle;
        }
    }

    &.content-card-table--full {
        th,
        td {
            padding: 13px 25px;
        }
    }
}

.content-card-table--fixed {
    th {
        width: 20%;
        text-align: right;
        padding-right: 1.1em;
    }

    td {
        padding-left: 1.1em;
    }
}

.content-card-table__modifiers {
    text-align: right;
}

.content-card-table__sub-text {
    margin-top: 0.6em;
    font-size: 0.87em;
    color: #abaeb2;
}

.content-card-list {
    font-size: 0.8em;
    line-height: 1.4em;
    padding: 0 0 0 30px;
}

.content-alert {
    margin-bottom: 25px;
    padding: 15px 25px;
    border-radius: 3px;
    background: #e3e8f2;
    color: #333;
    border: 1px solid #d3d9e4;

    .content-alert__heading {
        font-size: 16px;
        margin: 0 0 10px;
    }

    .content-alert__body {
        font-size: 12px;
    }

    &.content-alert--warn {
        background: $warm;

        .content-alert__body,
        .content-alert__heading {
            color: #fff;
        }
    }
}

.content-card-detail {
    margin-bottom: 0.8em;

    // The same as .input-label
    .content-card-detail__label {
        // font-size: 0.8em;
        display: block;
        overflow: hidden;
        // margin-bottom: 0.8em;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        // color: #778d99;

        // font-weight: normal;
        // display: block;
        // margin-bottom: 0.4em;
        // // cursor: pointer;
        // color: #778d99;
        // letter-spacing: 0.05em;

        @extend .input-label;
    }
}

.content-message {
    font-size: 0.8em;
    padding: 14px 0;
    background: #dae1ed;
}

.content-appendix {
    font-size: 12px;
    margin-bottom: 25px;
    text-align: center;
}

.mat-mdc-paginator {
    background: transparent;
}
