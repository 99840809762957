@import 'platform-ui/styles/public';

%input-disabled {
    cursor: not-allowed;
    background: $input-background-color;
    border-color: $input-background-color;
    opacity: 0.85;

    &:hover {
        border-color: $input-background-color;
    }
}

// Forms
//
// Example basic form element.
//
// Markup: form.html
//
// Style guide: components.form
// Form Inputs
//
// Form elements.
//
// :focus - Input focused.
// .ng-invalid.ng-touched - Angular input invalid.
// .is-disabled - Disabled input.
// .input--block - Full width input.
// .input--large - Larger input.
//
// Markup: input.html
//
// Style guide: components.form.inputs
%input-hover {
    border-color: darken($input-border-color, 10%);
}

%input-focus {
    background-color: #fff;
    border-color: $input-focus-color;
    outline: none;
}

#{$inputs},
textarea,
%input {
    &:not(.mat-mdc-input-element) {
        appearance: none;
        // background: $input-background-color;
        border-radius: $input-border-radius;
        border: $input-border-width solid $input-border-color;
        font-family: Arial, Helvetica, Verdana, sans-serif;
        font-size: rem(16px);
        // height: $input-height;
        line-height: $input-line-height;
        margin: 0;
        outline: 0;
        padding: $btn-vertical-padding 11px;
        text-align: left;
        transition: background 0.4s, border-color 0.4s;
        width: 100%;

        max-width: $input-width;

        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

        &.input--full {
            max-width: 100%;
        }

        &.ng-invalid.ng-touched {
        }

        &:hover {
            @extend %input-hover;
        }

        &:focus {
            @extend %input-focus;
        }

        &[disabled],
        &[readonly],
        &.is-disabled {
            &:hover,
            &:focus,
            & {
                @extend %input-disabled;
            }
        }
    }
}

textarea {
    font-family: Arial, Helvetica, Verdana, sans-serif;
    min-width: 100%;
    max-width: 100%;
    min-height: 129px;

    &.input--small {
        min-height: $input-height;
    }
}

.input-label {
    // font-size: 0.7em;
    font-weight: normal;
    display: block;
    margin-bottom: 0.4em;
    cursor: pointer;
    color: $btn-color;
    // letter-spacing: 0.05em;

    &[for]:hover {
        color: #33363f;
        transition: color 0.4s;
    }
}

.input-comment {
    font-size: 0.6em;
    padding: 0 10px;
    color: #8e939c;

    &.input-comment--block {
        display: block;
        padding: 7px 0 0;
    }
}

.input-sub-label {
    font-size: 0.6em;
    display: block;
    padding: 0 0 6px;
    cursor: pointer;
}

.input-group {
    position: relative;
    display: flex;
    // flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: $input-width;

    &.input-group--full {
        max-width: 100%;
    }

    &.input-group--small {
        max-width: 180px;
    }

    .input-group__append,
    .input-group__prepend {
        display: flex;
        z-index: 2;
    }

    .input-group__prepend {
        margin-right: -$input-border-width;
    }

    .input-group__append {
        margin-left: -$input-border-width;
    }

    .input-group__input:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group__input:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group__append,
    .input-group__prepend {
        .input-group__btn + .input-group__btn,
        .input-group__btn + .input-group__text,
        .input-group__btn + .input-group__select,
        .input-group__text + .input-group__btn,
        .input-group__text + .input-group__select,
        .input-group__text + .input-group__text,
        .input-group__select + .input-group__btn,
        .input-group__select + .input-group__text {
            margin-left: -$input-border-width;
        }
    }

    .input-group__input {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        z-index: 3;

        &:focus,
        &:hover,
        &.ng-invalid.ng-touched {
            z-index: 3;
        }
    }

    .input-group__select .mat-mdc-select-trigger {
        line-height: $input-line-height;
        height: $input-height;
    }

    .input-group__btn {
        border-width: $input-border-width;
        box-shadow: none;
        line-height: 14px;
    }

    .input-group__text {
        border-radius: $input-border-radius;
        border: $input-border-width solid #e2e7e7;
        color: #888;
        padding: 10px 0.7em;
        line-height: $input-line-height;
        font-size: 0.6em;
        font-weight: bold;
        text-transform: uppercase;
    }

    .input-comment {
        align-self: center;
    }

    .input-group__select {
        min-width: 100px;
    }

    .input-select .mat-mdc-select .mat-mdc-select-trigger {
        width: inherit;
    }

    > .input-group__append > .input-group__btn,
    > .input-group__append > .input-group__text,
    > .input-group__append > .input-group__select select,
    > .input-group__append > .input-group__select .mat-mdc-select-trigger,
    > .input-group__prepend > .input-group__btn:not(:first-child),
    > .input-group__prepend > .input-group__text:not(:first-child),
    > .input-group__prepend > .input-group__select:not(:first-child) select,
    > .input-group__prepend
        > .input-group__select:not(:first-child)
        .mat-mdc-select-trigger {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    > .input-group__prepend > .input-group__btn,
    > .input-group__prepend > .input-group__text,
    > .input-group__prepend > .input-group__select select,
    > .input-group__prepend > .input-group__select .mat-mdc-select-trigger,
    > .input-group__append > .input-group__btn:not(:last-child),
    > .input-group__append > .input-group__text:not(:last-child),
    > .input-group__append > .input-group__select:not(:last-child) select,
    > .input-group__append
        > .input-group__select:not(:last-child)
        .mat-mdc-select-trigger {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.ng-invalid.ng-touched {
        .input-group__input,
        .input-group__select select,
        .input-group__select .mat-mdc-select-trigger {
            border-color: $input-error-color;
            z-index: 3;
        }
    }

    &.input-group--accent {
        position: relative;

        > .input-group__prepend {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 4;

            > .input-group__text {
                width: 30px;
                text-align: center;
                border-color: transparent;
            }
        }

        .input-group__input,
        .input-group__select select {
            padding-left: 30px;
            border-radius: $input-border-radius;
        }

        .input-group__prepend + .input-group__input,
        .input-group__select select {
            padding-left: 30px;
        }
    }

    &.input-group--frameless {
        .input-group__btn,
        .input-group__text,
        .input-group__select select {
            border-color: $input-border-color;
        }

        > .input-group__prepend > .input-group__btn,
        > .input-group__prepend > .input-group__text,
        > .input-group__prepend > .input-group__select select {
            border-right-color: transparent;
        }

        .input-group__input,
        .input-group__select select {
            &:not(:last-child) {
                border-right-color: transparent;
            }

            &:not(:first-child) {
                border-left-color: transparent;
            }
        }

        > .input-group__append > .input-group__btn,
        > .input-group__append > .input-group__text,
        > .input-group__append > .input-group__select select {
            border-left-color: transparent;
        }
    }
}

.input-error:not([hidden]) {
    display: block;
    padding: 0.7em 0;
    cursor: pointer;

    // TODO override to match platform-ui styles.
    color: var(--foreground-error);

    &.input-error--filled {
        background-color: #bf171c;
        color: #fff;
    }
}

.input-optional {
    font-size: 0.65em;
    font-weight: bold;
    color: #5f9ea0;
}

.input-radio,
.input-checkbox {
    cursor: pointer;
    user-select: none;

    input {
        display: none;
    }

    &__label {
        font-size: 14px;
        display: inline-block;
        padding: 10px 11px;
        height: $input-height;
        line-height: $input-line-height;
        border-radius: $input-border-radius;
        border: $input-border-width solid $input-border-color;
        transition: background 0.3s, color 0.3s;
        background: #fff;

        &:hover {
            background: $input-border-color;
        }
    }
}

input:disabled:checked ~ .input-radio__label,
input:disabled:checked ~ .input-checkbox__label {
    cursor: not-allowed;
    opacity: 0.85;
}

input:disabled:not(:checked) ~ .input-radio__label,
input:disabled:not(:checked) ~ .input-checkbox__label {
    @extend %input-disabled;
}

.input-radio + .input-radio,
.input-checkbox + .input-checkbox {
    margin-left: 0.2em;
}

input:checked ~ .input-radio__label,
input:checked ~ .input-checkbox__label,
.input-checkbox--checked .input-checkbox__label {
    color: #fff;
    background: $input-focus-color;
    border-color: $input-focus-color;
}

// .mat-mdc-checkbox-frame {
//     border: $input-border-width solid $input-border-color;
// }

// .mat-mdc-checkbox .mat-mdc-checkbox-layout {
//     min-height: 2rem;
//     align-items: center;
// }

// .mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background,
// .mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background {
//     background: $input-focus-color;
// }

// .mat-mdc-checkbox-label {
//     font-size: 1rem;
// }

.input-select {
    position: relative;
    width: 100%;
    padding: 0;
    max-width: $input-width;
    display: block;

    &.input-select--full {
        max-width: 100%;

        .mat-mdc-select {
            .mat-mdc-select-trigger {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    select {
        @extend %input;

        height: 38px;
        padding: 0 40px 0 11px;
        cursor: pointer;
        appearance: none;
    }

    select::-ms-expand {
        display: none;
    }

    .mat-mdc-select {
        .mat-mdc-select-trigger {
            @extend %input;

            padding-right: 5px;
        }

        .mat-mdc-select-arrow {
            border-top-color: #778d99;
        }

        .mat-mdc-select-value {
            height: 17px;
        }

        &[disabled],
        &.is-disabled,
        &.mat-mdc-select-disabled {
            .mat-mdc-select-trigger {
                @extend %input-disabled;
            }
        }
    }

    &:focus-within {
        &:after {
            border-color: darken($input-border-color, 10%);
        }
    }

    &.input-select--arrowed {
        &:after {
            position: absolute;
            top: 50%;
            right: 15px;
            display: block;
            width: 10px;
            height: 10px;
            margin-top: -3px;
            content: '';
            transition: all 0.4s ease-in-out;
            transform: rotate(45deg) translateY(-50%);
            transform-origin: 50% 0;
            pointer-events: none;
            border-right: $input-border-width solid
                darken($input-border-color, 5%);
            border-bottom: $input-border-width solid
                darken($input-border-color, 5%);
        }
    }

    &.ng-invalid.ng-touched > select,
    &.ng-invalid.ng-touched > .mat-mdc-select .mat-mdc-select-trigger,
    > select.ng-invalid.ng-touched,
    > .mat-mdc-select.ng-invalid.ng-touched .mat-mdc-select-trigger {
        border-color: $input-error-color;
    }
}

.mat-mdc-select-panel.input-select__panel {
    min-width: auto !important;
    width: 100%;
    max-width: 900px !important;
}

.input-radioblock {
    position: relative;
    display: block;
    cursor: pointer;

    .input-radioblock__radio {
        display: none;
    }

    .input-radioblock__radio:checked + .input-radioblock__label,
    &.input-radioblock--is-checked .input-radioblock__label {
        color: #fff;
        background: $info;

        &:before {
            background: darken($info, 10%);
        }

        &:after {
            opacity: 1;
        }
    }

    .input-radioblock__label {
        font-size: 14px;
        line-height: 18px;
        display: block;
        min-height: 25px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 60px;
        transition: all 0.3s;

        &:before,
        &:after {
            content: ' ';
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 22.5px;
            width: 13px;
            height: 25px;
            transition: opacity 0.3s;
            transform: translate(-50%, -60%) rotateZ(45deg);
            transform-origin: center;
            opacity: 0;
            border-right: 5px solid #fff;
            border-bottom: 5px solid #fff;
        }

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 45px;
            height: 100%;
            transition: all 0.3s;
            background: #fafafa;
        }

        &:hover {
            background: #fafafa;

            &:before {
                background: #ededed;
            }
        }
    }
}

.radio-list,
.radio-list .radio-list__button {
    display: block;
    padding: 7px 0;
}

.selection-input {
    border: 1px solid #d6deeb;
    position: relative;
    display: block;

    .selection-input__summary {
        padding: 10px 15px;
    }

    .selection-input__clear {
        font-size: 0.8em;
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
        transition: all 0.3s;

        &:hover {
            background: #fafafa;
        }
    }

    .selection-input__title {
        margin-top: 0;
        font-size: 1.2em;
    }

    .selection-input__sub-title {
        color: #646464;
        font-size: 0.8em;
    }
}

a.selection-input:hover {
    background: #fafafa;
}

.tags-input {
    .tags-input__label {
        @extend %input;

        align-items: center;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        min-height: $input-height;
        padding: 4px 11px 0;
    }

    &.tags-input--full .tags-input__label {
        max-width: 100%;
    }

    &.ng-invalid.ng-touched {
        .tags-input__label {
            border-color: $input-error-color;
        }
    }

    &.tags-input--focused {
        > .tags-input__label {
            @extend %input-focus;
        }
    }

    &.tags-input--disabled {
        .tags-input__label {
            @extend %input-disabled;
        }
    }

    .tags-input__tag {
        display: inline-block;
        font-size: 0.8em;
        padding: 0.5em 0.7em;
        margin: 0 4px 4px 0;
        background: #dfedfa;
        color: #507bfb;
        border-radius: 3px;
    }

    .tags-input__remove {
        color: #507bfb;
        cursor: pointer;
        display: inline-block;
        margin-left: 4px;
        transition: color 0.3s;

        &:hover {
            color: #333;
        }
    }

    .tags-input__input:not(.mat-mdc-input-element) {
        background: transparent;
        border: none;
        height: 28px;
        min-width: 75px;
        outline: none;
        padding: 5px 0.7em;
    }

    .tags-input__controls {
        flex: 1;
        min-width: 160px;
        margin-bottom: 4px;
    }
}

.form {
    padding: 0;
    list-style: none;

    .form__item {
        margin-bottom: 0.8em;
    }

    .form__item--last {
        margin-bottom: 0;
    }

    .form--heading {
        margin-bottom: 15px;
    }

    .form__fieldset {
        padding: 0;
        border: none;
    }

    .form__selection-list {
        padding: 0;
        list-style: none;

        .form__item {
            margin-bottom: 7px;
        }
    }

    > li {
        display: block;
    }
}

.form--line {
    > .form__item {
        display: flex;
        margin-bottom: 0;
        align-items: center;
    }

    .input-label {
        margin-bottom: 0;
        padding-right: 15px;
    }

    .form__item--actions {
        justify-content: flex-end;
    }
}

.ng-submitted {
    #{$inputs},
    textarea,
    select {
        &.ng-invalid {
            border-color: $input-error-color;
        }
    }

    .input-group.ng-invalid {
        .input-group__input {
            border-color: $input-error-color;
            z-index: 3;
        }

        .input-select {
            > select,
            > .mat-mdc-select .mat-mdc-select-trigger {
                border-color: $input-error-color;
                z-index: 3;
            }
        }
    }

    .input-select {
        &.ng-invalid > select,
        &.ng-invalid > .mat-mdc-select .mat-mdc-select-trigger,
        > select.ng-invalid,
        > .mat-mdc-select.ng-invalid .mat-mdc-select-trigger {
            border-color: $input-error-color;
        }
    }

    .tags-input.ng-invalid > .tags-input__label {
        border-color: $input-error-color;
    }
}

.content-form {
}

.content-form__heading {
    font-size: 19px;
    margin: 0 0 1.2em 0;
}

.content-form__panel {
    border-bottom: 2px solid #eef0f1;

    &.content-form__panel--last {
        border-bottom: 0;
    }
}

.content-form__sidebar {
    @include break(tablet) {
        &:last-child {
            border-left: 2px solid #eef0f1;
        }

        &:first-child {
            border-left: 2px solid #eef0f1;
        }

        .content-form__section {
            padding: 1em 1.2em;
        }
    }
}

.content-form__section {
    padding: rem(22px);

    &--selected {
        display: block;
    }

    &--hidden {
        display: none;
    }

    &--actions {
        border-bottom: 0;
    }

    &--full {
        padding: rem(22px) 0;

        .content-form__heading {
            margin: 0 rem(22px) 1.2em rem(22px);
        }
    }
}

.mat-mdc-stepper-horizontal,
.mat-mdc-stepper-vertical {
    background: transparent;

    .mat-mdc-horizontal-content-container {
        padding: 0;
    }
}

.mat-mdc-select-search-inner {
    .mat-mdc-select-search-input {
        width: 100%;
        border-color: transparent;
    }

    .mat-mdc-select-search-clear {
        display: none;
    }
}

.input-label{
    font-size: 1.4rem;
}