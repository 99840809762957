.tariff-group-imaging {
    padding: 15px 0;
    border-bottom: 1px solid darken($body_bg_color, 5%);
    background: darken($body_bg_color, 2%);
}

.tariff-group-images {
    .tariff-group__image {
        margin-bottom: 18px;
        cursor: pointer;
    }
}
