.booking-list {
    &--simple {
        .booking-list-item-info__value {
            font-size: 14px;
        }
    }
}

.booking-list-item {
    &__details {
        padding: 15px;
        flex-grow: 1;
    }

    &__booking-ref {
        font-size: 11px;
        margin-left: 30px;
        padding: 9px 10px;
    }

    &__image {
        float: left;
        margin-right: 10px;
    }

    &__name {
        margin: 0;
    }

    /* &__length {
    } */
}

.booking-message {
    padding: 15px 25px;

    &__message {
        font-size: 0.7em;
    }

    sup {
        font-size: 0.7em;
    }

    &__icon {
        position: relative;
        margin-right: 10px;
    }

    &__message {
        vertical-align: middle;
    }

    &--coi {
        background: #ffc;
    }
}

.booking-stripe-icon {
    font-size: 20px !important;
    vertical-align: middle;
    color: #3a7ac1;
}

.booking-list-item-info {
    display: flex;
    flex-direction: column;
    padding: 0 18px;
    text-align: center;
    border-left: 1px solid #f8f9fc;
    justify-content: center;

    /* &__label {
    } */
    &__value {
        font-size: 18px;
        padding: 9px 0;
    }
}

.booking-pricing {
    font-size: 14px;
    line-height: 25px;
    color: #7b828a;

    td:first-child {
        padding-left: 0;
    }

    td:last-child {
        padding-right: 0;
    }

    &__price {
        text-align: right;
        color: #000;

        &--bold {
            font-weight: bold;
        }
    }

    &__minus {
        background: #f9f9f9;

        .booking-pricing__price {
            color: $error;
        }
    }

    &__total {
        td {
            border-top: 1px solid #f5f5f5;
        }
    }

    .booking-pricing__refund {
        font-size: 0.7em;
    }

    .booking-pricing__rentman-id {
        font-size: 0.7em;
        color: #999;
    }
}

.booking-customer-details {
    &__list {
        font-size: 14px;
        line-height: 22px;
        list-style: none;
        padding: 0;

        > li {
            margin-bottom: 14px;
        }
    }

    &__label {
        color: darken(#a3b2ba, 15%);
    }
}

.booking-activities {
    &__activity-description {
        font-size: 0.7em;
        margin-top: 5px;
        color: #333;
    }
}
