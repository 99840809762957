@mixin cssVars() {
    :root {
        --background-active-button:#bdc3cc;
        --background-active-error:#cd0000;
        --background-active-extra:#9854bb;
        --background-active-info:#316ba9;
        --background-active-neutral:#272c37;
        --background-active-primary:#474d65;
        --background-active-success:#295;
        --background-active-warn:#ffa521;
        --background-body:#e7ecf3;
        --background-button:#dadde2;
        --background-card:#ffffff;
        --background-error:#e60000;
        --background-extra:#a366c2;
        --background-hover-button:#ccd0d7;
        --background-hover-error:#f00;
        --background-hover-extra:#ae78c9;
        --background-hover-info:#4584c9;
        --background-hover-neutral:#3c4455;
        --background-hover-primary:#5b6383;
        --background-hover-success:#2cc36b;
        --background-hover-warn:#ffb03a;
        --background-info:#3777bd;
        --background-input:#ffffff;
        --background-input-disabled:#dadde2;
        --background-light-card:#f2f4f8;
        --background-light-error:#f4e3e3;
        --background-light-extra:#ebe5ee;
        --background-light-info:#e6ebf0;
        --background-light-neutral:#edeef0;
        --background-light-primary:#ebebee;
        --background-light-success:#e6f1eb;
        --background-light-warn:#f2eade;
        --background-neutral:#313846;
        --background-primary:#515874;
        --background-success:#27ae60;
        --background-warn:#ffba54;
        --border:#c0c3ca;
        --border-error:#e60000;
        --border-extra:#a366c2;
        --border-info:#3777bd;
        --border-input:#dadde2;
        --border-inverted:#ffffff;
        --border-light:#dadde2;
        --border-lightest:#ebeff5;
        --border-neutral:#313846;
        --border-primary:#515874;
        --border-success:#27ae60;
        --border-warn:#ffba54;
        --foreground:#313846;
        --foreground-error:#e60000;
        --foreground-extra:#a366c2;
        --foreground-info:#3777bd;
        --foreground-inverted:#ffffff;
        --foreground-link:#3777bd;
        --foreground-link-hover:#5891cf;
        --foreground-link-visited:#8c46af;
        --foreground-neutral:#313846;
        --foreground-on-error:#FFF;
        --foreground-on-extra:#FFF;
        --foreground-on-info:#FFF;
        --foreground-on-neutral:#FFF;
        --foreground-on-primary:#FFF;
        --foreground-on-success:#FFF;
        --foreground-on-warn:#343434;
        --foreground-primary:#515874;
        --foreground-secondary:#5d636f;
        --foreground-success:#27ae60;
        --foreground-tertiary:#a6aab2;
        --foreground-warn:#ffba54;
        --mat-autocomplete-background-color:#ffffff;
        --mat-autocomplete-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        --mat-datepicker-calendar-container-background-color:#ffffff;
        --mat-datepicker-calendar-container-elevation-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        --mat-datepicker-calendar-date-outline-color:transparent;
        --shadow-large:0 1px 2px 0px rgba(49,56,70,0.1), 0 6px 6px -2px rgba(49,56,70,0.1), 0 10px 10px -6px rgba(49,56,70,0.2);
        --shadow-medium:0 1px 2px 0px rgba(49,56,70,0.1), 0 4px 4px -2px rgba(49,56,70,0.1), 0 6px 6px -4px rgba(49,56,70,0.2);
        --shadow-small:0 1px 2px 0px rgba(49,56,70,0.1), 0 1px 1px -1px rgba(49,56,70,0.1), 0 2px 2px -2px rgba(49,56,70,0.2);
        --mdc-filled-text-field-caret-color: #3f51b5;
        --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
        --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, .87);
        --mdc-filled-text-field-container-color: whitesmoke;
        --mdc-filled-text-field-disabled-container-color: #fafafa;
        --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, .6);
        --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, .38);
        --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, .87);
        --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, .38);
        --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, .6);
        --mdc-filled-text-field-error-focus-label-text-color: #f44336;
        --mdc-filled-text-field-error-label-text-color: #f44336;
        --mdc-filled-text-field-error-caret-color: #f44336;
        --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, .42);
        --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, .06);
        --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, .87);
        --mdc-filled-text-field-error-active-indicator-color: #f44336;
        --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
        --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
        --mdc-outlined-text-field-caret-color: #3f51b5;
        --mdc-outlined-text-field-focus-outline-color: #3f51b5;
        --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, .87);
        --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, .6);
        --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, .38);
        --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, .87);
        --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, .38);
        --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, .6);
        --mdc-outlined-text-field-error-caret-color: #f44336;
        --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
        --mdc-outlined-text-field-error-label-text-color: #f44336;
        --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, .38);
        --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, .06);
        --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, .87);
        --mdc-outlined-text-field-error-focus-outline-color: #f44336;
        --mdc-outlined-text-field-error-hover-outline-color: #f44336;
        --mdc-outlined-text-field-error-outline-color: #f44336;
        --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, .38);

        --mat-datepicker-calendar-date-selected-state-text-color: white;
        --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, .4);
        --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
        --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, .3);
        --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, .3);
        --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
        --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, .2);
        --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, .2);
        --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
        --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
        --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, .54);
        --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, .54);
        --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, .54);
        --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, .54);
        --mat-datepicker-calendar-header-divider-color: whitesmoke;
        --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, .54);
        --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, .38);
        --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, .18);
        --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, .87);
        --mat-datepicker-calendar-date-outline-color: transparent;
        --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, .38);
        --mat-datepicker-calendar-date-preview-state-outline-color: whitesmoke;
        --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, .87);
        --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, .38);
        --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, .38);
        --mat-datepicker-calendar-container-background-color: white;
        --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, .87);

        --mat-datepicker-calendar-body-label-text-size: 14px;

        --mat-datepicker-calendar-period-button-text-size: 14px;
        --mat-datepicker-calendar-header-text-size: 14px;
        --mat-select-panel-background-color: white;
        --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

        --mdc-slider-handle-color: #3f51b5;
        --mdc-slider-focus-handle-color: #3f51b5;
        --mdc-slider-hover-handle-color: #3f51b5;
        --mdc-slider-active-track-color: #3f51b5;
        --mdc-slider-inactive-track-color: #3f51b5;
        --mdc-slider-with-tick-marks-active-container-color: #fff;
        --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
        --mat-mdc-slider-ripple-color: #3f51b5;
        --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, .05);
        --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, .2);

        --mdc-slider-handle-shape: 50%;
        --mdc-slider-handle-width: 20px;
        --mdc-slider-handle-height: 20px;

        --mdc-slider-handle-color: #3f51b5;
        --mdc-slider-focus-handle-color: #3f51b5;
        --mdc-slider-hover-handle-color: #3f51b5;
        --mdc-slider-active-track-color: #3f51b5;
        --mdc-slider-inactive-track-color: #3f51b5;
        --mdc-slider-with-tick-marks-active-container-color: #fff;
        --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
        --mat-mdc-slider-ripple-color: #3f51b5;
        --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, .05);
        --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, .2);

        --mdc-slider-label-container-color: black;
        --mdc-slider-label-label-text-color: white;
        --mdc-slider-disabled-handle-color: #000;
        --mdc-slider-disabled-active-track-color: #000;
        --mdc-slider-disabled-inactive-track-color: #000;
        --mdc-slider-with-tick-marks-disabled-container-color: #000;

        --mat-slider-value-indicator-opacity: 0.6;
        --mat-slider-value-indicator-padding: 0 12px;
        --mat-slider-value-indicator-width: 39px;
        --mat-slider-value-indicator-height: 32px;
        
        --mat-paginator-container-text-color: rgba(0, 0, 0, .87);
        --mat-paginator-container-background-color: white;
        --mat-paginator-enabled-icon-color: rgba(0, 0, 0, .54);
        --mat-paginator-disabled-icon-color: rgba(0, 0, 0, .12);
        --mat-paginator-container-size: 56px;
        --mat-form-field-container-vertical-padding: 8px;

        --mdc-outlined-text-field-container-shape: 4px;

        --mat-menu-container-color: white;
        --mat-menu-item-leading-spacing: 16px;
        --mat-menu-item-trailing-spacing: 16px;

        --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, .04);

        --mdc-secondary-navigation-tab-container-height: 48px;
        --mdc-tab-indicator-active-indicator-color: #3f51b5;
        --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, .38);
        --mat-tab-header-pagination-icon-color: #000;
        --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, .6);
        --mat-tab-header-active-label-text-color: #3f51b5;
        --mat-tab-header-active-ripple-color: #3f51b5;
        --mat-tab-header-inactive-ripple-color: #3f51b5;
        --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, .6);
        --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, .6);
        --mat-tab-header-active-focus-label-text-color: #3f51b5;
        --mat-tab-header-active-hover-label-text-color: #3f51b5;
        --mat-tab-header-active-focus-indicator-color: #3f51b5;
        --mat-tab-header-active-hover-indicator-color:  #3f51b5;

        --mat-select-panel-background-color: white;
        --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, .87);
        --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, .38);
        --mat-select-placeholder-text-color: rgba(0, 0, 0, .6);
        --mat-select-enabled-arrow-color: rgba(0, 0, 0, .54);
        --mat-select-disabled-arrow-color: rgba(0, 0, 0, .38);
        --mat-select-focused-arrow-color: rgba(63, 81, 181, .87);
        --mat-select-invalid-arrow-color: rgba(244, 67, 54, .87);
        
        --mdc-icon-button-icon-color: inherit;
        --mat-mdc-button-persistent-ripple-color: #000;
        --mat-mdc-button-ripple-color: rgba(0, 0, 0, .1);

        --mdc-icon-button-icon-size: 24px;
        --mdc-icon-button-disabled-icon-color: black;
        --mdc-icon-button-disabled-icon-opacity: 0.38;
    }
}