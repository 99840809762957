.user-locations-change {
    padding-left: 24px;
    padding-right: 24px;
    
    .mat-mdc-mdc-dialog-content.mdc-dialog__content {
        padding-left: 0px;
    }

    .title-container {
        margin-top: 20px;
    }

    .input-label{
        font-size: 14px;
    }
    .input-select {
        font-size: 14px;
        border: 1px solid #cedbdf;
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-left-width: 1px;
        border-top-style: solid;
        border-right-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        border-top-color: rgb(206, 219, 223);
        border-right-color: rgb(206, 219, 223);
        border-bottom-color: rgb(206, 219, 223);
        border-left-color: rgb(206, 219, 223);
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
        padding: 10px;
    }
    .mat-mdc-mdc-dialog-actions{
        justify-content: end;
    }
}

.dialog-container{
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;

    .dialog-search-result {
        font-size: 14px;
        color: black;
    }
}

.dialog-heading{
    padding-left: 24px;
}