@import '../../variables/colors';
@import 'platform-ui/styles/public';

.servicing-entry__sub-items-content {
    background: #fff;
}

.servicing-entry__item {
    display: block;
    border-bottom: 1px solid #e2e2e2;
}

.servicing-entry__item-content {
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;
}

.servicing-entry__item-header {
    margin-bottom: 10px;
}

.servicing-entry__item-details {
    padding-left: 25px;
}

.servicing-entry__item-heading {
    margin-top: 0;
    font-size: 1.1em;

    &.servicing-entry__item-heading--padded {
        padding-left: 31px;
    }
}

.servicing-entry__item-sub-heading {
    margin-top: 0;
    font-size: 0.9em;
    margin-bottom: 10px;
    color: #777;
}

.servicing-entry__item-actions {
    margin: 10px rem(22px) 0;
}

.servicing-entry__item-no-charges {
    font-size: 0.9em;
    text-align: center;
    color: #666;
}

.servicing-entry__charge {
    display: block;
    padding: 5px 0;

    &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
    }
}

.servicing-entry__item-charges-charge > div {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 0.8em;
}
