$navigation-width: 200px;

$layout-width-data: (
    primary: (
        min: rem(480px),
        max: rem(800px),
        wide: rem(1100px),
    ),
    secondary: (
        min: rem(240px),
        max: rem(320px),
    ),
    one-half-width: (
        base: rem(400px),
    ),
    one-third-width: (
        base: rem(240px),
    ),
    nav: (
        base: rem($navigation-width),
    ),
    inner-spacing: (
        base: spacing(),
    ),
    outer-spacing: (
        min: spacing(large),
        max: spacing(x-large),
    ),
    form-field: (
        min: rem(150px),
    ),
);

@function layout-width($name, $value: base) {
    $fetched-value: map-get(map-get($layout-width-data, $name), $value);

    @if type-of($fetched-value) {
        @return $fetched-value;
    } @else {
        @error 'Column `#{$name} - #{$value}` not found. Available columns: #{available-names($layout-width-data)}';
    }
}

// Add padding to elements within the latout when on mobile devices. Used for:
// - page-header
// - form-actions
@mixin layout-spaced($size: large) {
    padding: spacing($size) spacing($size) 0;

    @include content-when-medium() {
        padding-right: 0;
        padding-left: 0;
    }
}
