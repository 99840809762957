$stack-spacing: $spacing-data;

@mixin stack-distribution() {
    &--d-equalSpacing > .stack__wrapper {
        justify-content: space-between;
    }
    &--d-leading > .stack__wrapper {
        justify-content: flex-start;
    }
    &--d-trailing > .stack__wrapper {
        justify-content: flex-end;
    }
    &--d-center > .stack__wrapper {
        justify-content: center;
    }
    &--d-fill > .stack__wrapper > * {
        flex: 1 1 auto;
    }
    &--d-fillEvenly > .stack__wrapper > * {
        flex: 1 0;
        min-width: -webkit-fit-content;
        min-width: fit-content;
    }
}

@mixin stack-alignment() {
    &--a-leading > .stack__wrapper {
        align-items: flex-start;
    }
    &--a-trailing > .stack__wrapper {
        align-items: flex-end;
    }
    &--a-center > .stack__wrapper {
        align-items: center;
    }
    &--a-fill > .stack__wrapper {
        align-items: stretch;
    }
    &--a-baseline > .stack__wrapper {
        align-items: baseline;
    }
}

@mixin stack-space($index: medium) {
    > .stack__wrapper {
        margin-top: spacing($index) * -1;
        margin-left: spacing($index) * -1;

        > * {
            margin-left: spacing($index);
            margin-top: spacing($index);
        }
    }

    &:not(.stack--vertical) {
        > .stack__wrapper > * {
            max-width: 100%;
        }
    }
}

@mixin stack-spacing() {
    @each $index, $value in $stack-spacing {
        &--s-#{$index} {
            @include stack-space($index);
        }
    }
}

@mixin stack-component() {
    .stack {
        @include stack-space;
        > .stack__wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
        }

        &.stack--vertical > .stack__wrapper {
            flex-direction: column;
            font-size: 14px;
            color: black;
        }

        &.stack--noWrap > .stack__wrapper {
            flex-wrap: nowrap;
        }

        @include stack-distribution();
        @include stack-alignment();
        @include stack-spacing();
    }

    .stack-item {
        display: block;
    }

    .stack-item--fill {
        flex: 1 1 auto;
    }
}
