@import 'platform-ui/styles/public';

.pricing-quotation {
    h3 {
        font-size: 16px;
    }

    .pricing-quotation__details {
        margin-bottom: 14px;
    }

    .pricing-quotation__section {
        border: 2px solid #eee;
        border-radius: 5px;
    }

    .pricing-quotation__section-content {
        display: block;

        &:not(:last-child) {
            border-bottom: 2px solid #eee;
        }
    }

    .pricing-quotation__section-label {
        margin-top: 7px;
        line-height: rem(16px);
    }

    .pricing-quotation__section-details {
        margin-top: 7px;
        line-height: rem(18px);
    }

    .pricing-quotation__section-configuration {
        padding: 0 14px 10px 50px;
    }

    .mat-mdc-checkbox-inner-container {
        margin-top: 14px;
        margin-right: 7px * 3;
    }

    .mat-mdc-checkbox {
        padding: 10px 14px;
        display: block;
    }

    .mat-mdc-checkbox-layout {
        display: flex;
    }

    .mat-mdc-checkbox-label {
        white-space: normal;
        flex: 1;
    }
}

.taiff-quotation-table {
    font-size: 0.8em;
    td {
        padding: 6px 0;
    }

    .taiff-quotation-table__total {
        td {
            padding-top: 15px;
            font-weight: 700;
        }
    }
}

.taiff-quotation-table--accent {
    color: rgba(0, 0, 0, 0.56);
}

.taiff-quotation-sidebar__section {
    padding: 14px 0;
}

.taiff-quotation-sidebar__section:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.taiff-quotation-sidebar__heading {
    margin-top: 0;
}

.pricing-quotation__extras {
    border: 1px solid #eee;
    border-width: 1px 0 0 1px;

    .pricing-quotation__extra {
        display: flex;
        height: 100%;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
}

.pricing-quotation__pricing {
    border-top: 1px solid #eee;
}

.pricing-upgrade {
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.4s;
    border: 2px solid $success;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    &:hover {
        background: #fafafa;
    }
}

.pricing-upgrade__title {
    display: inline-block;
    overflow: hidden;
    width: 180px;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.pricing-upgrade__content {
    margin-bottom: 7px;
}

.pricing-upgrade__pricing {
    margin-top: 4px;
}

.pricing-upgrade__price {
    color: $success;
}

.pricing-quotation__total {
    padding: 19px 37px;
    text-align: center;
    color: #fff;
    background: #188435;

    .pricing-quotation__total-amount,
    .pricing-quotation__total-sub {
        display: inline-block;
        vertical-align: baseline;
    }

    .pricing-quotation__total-amount {
        font-size: 24px;
    }

    .pricing-quotation__total-sub {
        font-size: 10px;
        margin-left: 4px;
    }
}

.pricing-quotation__daily {
    font-size: 12px;
    padding: 0 25px;
    color: #333;
}

.pricing-quotation__specifications {
    .tariff-specifications__specifications {
        display: flex;
        overflow-y: auto;
        max-height: 250px;
        flex-wrap: wrap;
    }

    .tariff-specifications__show-all {
        width: 100%;
        text-align: center;
    }

    .tariff-specification {
        text-align: center;
        flex-grow: 1;
    }
}

#corporate_tariff_search .mat-select-search-clear {
    visibility: hidden;
}