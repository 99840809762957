.user-sidebar {
    padding: 60px 0;
    text-align: center;

    .user-sidebar--name {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
        letter-spacing: 1px;
        color: #000;
    }

    .user-sidebar--title {
        font-size: 14px;
        display: block;
        margin-bottom: 22px;
        color: #999;
    }
}

.users {
    display: flex;
    flex-wrap: wrap;

    .user--user {
        display: flex;
        margin: 5px 5px 0 0;
        color: #333;
        border: 1px solid #eee;
        align-items: center;

        &:hover {
            background: #f9f9f9;
        }
    }

    .user--user-name {
        margin: 15px;
    }
}

.user-label {
    position: relative;
    margin: 0 0.2em;

    &__image {
        display: inline-block;
        height: 1em;
        vertical-align: top;
    }

    &__name {
        // vertical-align: middle;
    }
}

.user-list {
    .user-list__thumbnail {
        margin-right: 14px;
    }
}

.permissions-table {
    .permissions-table__actions {
        padding: 0;
        text-align: right;
    }
}
