@import '../variables/colors';

.snackbar--success {
    background-color: $success;
    color: $white;
}

.snackbar--error {
    background-color: $error;
    color: $white;
}

.snackbar--warm {
    background-color: $warm;
    color: $white;
}

.snackbar--info {
    background-color: $info;
    color: $white;
}
