table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    td,
    th {
        font-size: inherit;
        overflow: visible;
        margin: 0;
        padding: 0.5em 1em;
        text-align: left;
    }
}

.table {
    &.table--plain {
        thead {
            border-bottom: 0;
        }
    }

    &.table--full {
        width: 100%;
        max-width: 100%;
    }

    &.table--fixed {
        table-layout: fixed;
    }

    &.table--default {
        border: 1px solid #e0e0e0;

        .table__thead {
            background: #fafafa;
        }
    }

    &.table--center {
        th,
        td {
            text-align: center;
        }
    }
}

.table-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
