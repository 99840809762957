@mixin form-field-component {
    $min-height: control-height();
    $min-width: layout-width(form-field, min);
    $border-color: var(--border-input);

    .form-field__label-wrapper {
        margin-bottom: spacing(xx-small);
        font-weight: font-weight();
    }

    .form-field__optional-label {
        font-style: italic;
        color: var(--foreground-tertiary);
        font-size: font-size(small);
    }

    .form-field__content {
        position: relative;
    }

    .form-field__content-flex {
        display: flex;
        align-items: center;
    }

    .form-field {
        display: block;

        .mat-mdc-input-element {
            @include form-field-input;
            flex: 1;
        }

        textarea.mat-mdc-input-element {
            padding: spacing(x-small) spacing(small);
            font-size: font-size();
            line-height: line-height();
            font-family: Arial, Helvetica, Verdana, sans-serif;

            @include content-when-medium {
                font-size: font-size(small);
            }
        }

        textarea[pAutoSize] {
            resize: none;
        }

        $vertical-padding: ($min-height - rem(17px)) / 2;

        .mat-mdc-select {
            display: block;
        }

        .mat-mdc-select-trigger {
            background-color: #fff;
            display: block;
            padding: $vertical-padding spacing(x-small) $vertical-padding
                spacing(small);
            font-size: inherit;
            line-height: 1;
            height: $min-height;
            border: border-width() solid $border-color;
            border-radius: border-radius();
            transition: transitions-fast(box-shadow, border);

            min-width: $min-width;
        }
    }

    .form-field--s-x-narrow {
        max-width: layout-width(form-field, min);
    }

    .form-field--s-narrow {
        max-width: layout-width(secondary, max);
    }

    .form-field--s-wide {
        max-width: layout-width(primary, min);
    }

    // .form-field--s-full {
    //     max-width: none;
    // }

    .form-field--invalid {
        .mat-mdc-input-element,
        .mat-mdc-select-trigger {
            border-color: var(--foreground-error);
            box-shadow: 0 0 0 1px var(--foreground-error);
            transition: none;

            // TODO default platform styles override.
            &.ng-invalid:focus {
                border-color: var(--border-info);
            }
        }
    }

    // Simple arrow for native select boxes.
    .form-field__content--arrow {
        position: absolute;
        top: 50%;
        right: spacing(x-small) + (border-width() * 2);
        transform: translateY(-50%);

        // From .mat-mdc-select-arrow
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        margin: 0 4px;
        color: rgba(0, 0, 0, 0.54);
    }

    .form-field__help-wrapper,
    .form-field__error-wrapper {
        margin-top: spacing(xxx-small);
    }

    .form-field__error-label {
        color: var(--foreground-error);
    }

    .form-field__help-label {
        color: var(--foreground-secondary);
    }

    .form-field__prefix,
    .form-field__suffix {
        // TODO to match material datepicker toggle.
        min-width: 50px;
        text-align: center;
    }

    .form-field__suffix {
        padding-left: spacing(small);
    }

    .form-field__prefix {
        padding-right: spacing(small);
    }
}
