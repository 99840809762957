@mixin badge-component {
    .badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: font-size(x-small);
        line-height: line-height();
        padding: 0 spacing(x-small);
        border-radius: border-radius();
    }

    .badge__wrapper {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }

    @each $name in $variants {
        .badge--v-#{$name} {
            background-color: var(--background-light-#{$name});
            color: var(--foreground-#{$name});
        }

        .badge--v-#{$name}.badge--strong {
            background-color: var(--background-#{$name});
            color: var(--foreground-on-#{$name});
        }
    }

    .badge--current-color {
        background-color: var(--background-light-badge);
        color: var(--foreground-badge);
    }

    .badge--current-color.badge--strong {
        background-color: var(--foreground-badge);
        color: var(--foreground-on-badge);
    }
}
