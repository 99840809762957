@function control-height() {
    @return rem(40px);
}

@mixin form-field-input() {
    $min-height: control-height();
    $border-color: var(--border-input);

    background-color: var(--background-input);
    appearance: none;
    font-size: inherit;
    line-height: line-height();
    min-height: $min-height;
    width: 100%;
    padding: 0 spacing(small);
    margin: 0;
    appearance: none;
    border: border-width() solid $border-color;
    box-sizing: border-box;
    border-radius: border-radius();
    outline: none;
    transition: transitions-fast(box-shadow, border);

    // TODO override of default platform style.
    max-width: 100%;

    &:focus {
        border-color: var(--border-info);
        box-shadow: 0 0 0 1px var(--border-info);
        outline: 0;
        transition: none;
    }

    &[disabled] {
        background-color: var(--background-input-disabled);
    }
}
