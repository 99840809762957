@mixin alert-component {
    .alert {
        display: flex;

        padding: spacing();
        border-radius: border-radius(large);
        background: var(--background-card);
        box-shadow: var(--shadow-small);

        border: border-width() solid var(--border);
    }

    .alert__title {
        flex: 1;
        font-weight: font-weight(semi-bold);
    }

    .alert__body {
        flex: 1 1;
        width: 100%;
    }

    .alert__content {
        ul,
        ol {
            padding-left: spacing(large);
        }
    }

    .alert__close {
        margin-top: spacing(xx-small);
        margin-left: spacing(x-small);
        transition: transitions-fast(color);
        cursor: default;

        &:hover {
            color: var(--foreground-secondary);
        }
    }

    @each $name in $variants {
        .alert--v-#{$name} {
            background-color: var(--background-light-#{$name});
            border-color: var(--background-#{$name});
            // color: var(--foreground-on-#{$name});

            .alert__title {
                color: var(--foreground-#{$name});
            }
        }
    }
}
