@import 'platform-ui/styles/public';

.input-select__insurance-modifier {
    [value='string:Standard'] {
        background: #ebf1de;
    }

    [value='string:SoftRestricted'] {
        background: #fcd5b4;
    }

    [value='string:HardRestricted'] {
        background: #e6b8b7;
    }
}

.tariff-specifications {
    padding: 15px 20px;
    background: #f9f9f9;
}

.tariff-specifications__specifications {
    display: flex;
    flex-wrap: wrap;
}

.tariff-specification,
.tariff-specifications__show-all,
.tariff-specifications__more-btn {
    font-size: 12px;
    position: relative;
    display: inline-block;
    margin: 2px 5px 2px 0;
    padding: 8px 12px;
    vertical-align: middle;
    background: #fff;
}

.tariff-specification {
    &--clickable {
        cursor: pointer;
        user-select: none;
    }

    &--add {
        &:hover {
            color: #fff;
            background: $success;
        }
    }

    &--remove {
        &:hover {
            color: #fff;
            background: $error;
        }
    }
}

.tariff-specification__actions {
    font-size: 12px;
    display: inline-block;
    margin-right: -5px;
    margin-left: 5px;
    list-style: none;
    vertical-align: middle;

    .tariff-specification__action {
        line-height: 17px;
        width: 17px;
        height: 17px;
        cursor: pointer;
        transition: all 0.4s;
        text-align: center;
        color: #efefef;
        border-radius: 50%;

        &:hover {
            color: $primary;
            background-color: #efefef;
        }
    }

    .tariff-specification__action--remove {
        &:hover {
            color: $error;
        }
    }
}

.tariff-specifications-input {
    margin-bottom: 7px;

    .tariff-specifications-input__input {
        float: left;
        width: auto;
    }
}

.tariff-images-form {
    &__image {
        margin-bottom: 7px;
    }

    &__no-result {
        font-size: 12px;
        padding: 10px 0;
    }
}

.markup-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .markup-option__input {
        width: 10%;
    }

    .markup-option__slider {
        width: 73%;
        margin: 0 1%;
        margin-top: 1em;
    }

    .markup-option__pricing {
        font-size: 14px;
        display: flex;
        width: 15%;
        align-items: center;
        justify-content: space-between;
    }

    .markup-option__price-details {
        font-size: 10px;
        text-align: right;
        white-space: nowrap;
    }
}

$testing-padding: rem(22px);

.price-testing-form {
    background: #fafafa;
    padding: 1.4em $testing-padding rem(6px);
    margin-right: $testing-padding * -1;
    margin-left: $testing-padding * -1;
    margin-bottom: $testing-padding;
}

.tariff-meshes {
    .tariff-meshes__mesh {
        display: block;
        padding: 3px;
        border: 3px solid #fff;
    }

    .tariff-meshes__mesh--selected {
        border-color: $primary;
    }

    .tariff-meshes__img {
        display: block;
        width: 100%;
    }
}

.markup-option__container {
    position: relative;
    margin-top: 10px;
}

.markup-option__slider {
    width: 73%;
    margin: 0 1%;
}

.markup-option__slider-label-left {
    font-size: 0.8em;
    color: #999;
    position: absolute;
    left: 10px;
}

.quote-builder-markup-option__slider-label-left {
    font-size: 0.8em;
    color: #999;
}

.markup-option__slider-label-right {
    font-size: 0.8em;
    color: #999;
    position: absolute;
    right: -10px;
}

.quote-builder-markup-option__slider-label-right {
    font-size: 0.8em;
    color: #999;
    margin-left: 100px;
}

.quote-builder-markup-option__slider-separator {
    margin-bottom: -15px;
}

mat-slider.mat-mdc-slider {
    width: 100%;
}