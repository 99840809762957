.datepicker {
    font: normal 14px sans-serif;
    z-index: 99999;
    display: inline-block;
    user-select: none;
    color: #333;
    border: 1px solid #ddd;
    background: #fff;
}

.datepicker .datepicker__header {
    font-weight: bold;
    line-height: 22px;
    position: relative;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid #ddd;
    background: #fcfcfc;
}

.datepicker .datepicker__header .datepicker__header-btn {
    font: normal 14px sans-serif;
    line-height: 22px;
    position: absolute;
    cursor: pointer;
    color: #555;
    border: none;
    outline: none;
    background: transparent;
}

.datepicker .datepicker__header .datepicker__header-btn:hover {
    color: #333;
}

.datepicker .datepicker__header .datepicker__header-btn--left {
    left: 10px;
}

.datepicker .datepicker__header .datepicker__header-btn--right {
    right: 10px;
}

.datepicker .datepicker__days .adp-time {
    font-weight: bold;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

.datepicker .datepicker__days,
.datepicker .datepicker__months,
.datepicker .datepicker__years {
    overflow: hidden;
    width: 210px;
    margin: 10px;
    text-align: center;
}

.datepicker .datepicker__days > .datepicker__days-of-week,
.datepicker .datepicker__days > .datepicker__day {
    font-size: 0.7em;
    line-height: 28px;
    float: left;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 30px;
    transition: all 0.3s;
    border: 1px solid transparent;
}

.datepicker .datepicker__days > .datepicker__day {
    cursor: pointer;

    &:hover {
        background: #eee;
    }
}

.datepicker .datepicker__days > .datepicker__days-of-week {
    font-size: 0.79em;
    font-weight: bold;
}

.datepicker .datepicker__days > .datepicker__day--unselectable {
    opacity: 0.15;
}

.datepicker .datepicker__days > .datepicker__day.datepicker__day--selected {
    background: #e0e0e0;
}

.datepicker .datepicker__days > .datepicker__day.datepicker__day--today {
    border-bottom-color: $link_color;
}

.datepicker .datepicker__days:after {
    display: block;
    clear: left;
    height: 0;
    content: '';
}

.datepicker .datepicker__months .datepicker__months-month,
.datepicker .datepicker__years .datepicker__years-year {
    font-size: 0.7em;
    line-height: 28px;
    float: left;
    box-sizing: border-box;
    width: (210px / 3);
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid transparent;

    &:hover {
        background: #eee;
    }
}

.datepicker .datepicker__years .datepicker__years-year {
    width: (210px / 2);
}

.datepicker input[type='range'] {
    width: 100%;
}

.timepicker-input {
    // Unused.
}

.datetime-input {
    display: flex;
    max-width: 280px;

    .datetime-input__date {
        flex: 1;
    }

    .datetime-input__time {
        display: inline-block;
        margin-left: 4px;
    }
}

.input-errors-date-picker {
    margin-top: -4rem;
}

button.mat-mdc-icon-button:hover,
button.mat-calendar-period-button:hover {
    background-color: rgba(245, 245, 245, 1);
}

button.mat-calendar-period-button:hover {
    border-radius: 5px;
}

.mat-datepicker-content .mat-datepicker-content-container .mat-calendar {
    height: 362px;
}