.activities-list {
    .activities-list__date,
    .activities-list__user {
        align-self: center;
    }

    .activities-list__user {
        margin-left: 10px;
    }
}
