.home-header {
    padding: 200px 0;
    text-align: center;
    color: $white;
    background: #444;

    &.home-header__v1 {
        padding: 40px 0;
        color: $black;
        background: transparent;

        &:hover {
            transition: background 0.3s;
            color: $white;
            background: lighten($labs_blue, 18%);
        }
    }
}

.home-box {
    padding: 40px 0;

    .home-box--heading {
        text-align: center;
    }
}
