@import './common';
@import '../stack/stack.component';
@import '../layout/layout.component';
@import '../item-list/item-list.component';
@import '../card/card.component';
@import '../page/page.component';
@import '../page/components/page-header.component';
@import '../action-menu/action-menu.component';
@import '../alert/alert.component';
@import '../badge/badge.component';
@import '../form-actions/form-actions.component';
@import '../form-field/form-field.component';
@import '../list/list.component';
@import '../spinner/spinner.component';

/**
 * None theme specific styles.
 */
@mixin platform-core() {
    @include cssVars();

    body,
    html {
        text-transform: none;
        letter-spacing: normal;
        font-weight: 400;
        color: var(--foreground);
        background: var(--background-body);
    }

    html {
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: 100%;
        text-size-adjust: 100%;
        font-size: ($base-font-size / $default-browser-font-size) * 100%;
        text-rendering: optimizeLegibility;

        font-family: Arial, Helvetica, Verdana, sans-serif;
        box-sizing: border-box;
    }

    body {
        min-height: 100%;
        font-size: font-size();
        line-height: line-height();

        @include content-when-medium() {
            font-size: font-size(small);
        }
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
        margin: 0;
        padding: 0;
    }

    * {
        margin: 0;
        padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        font-size: 1em;
        font-weight: 400;
    }

    @include headings-base();

    .link {
        color: var(--foreground-link);
        text-decoration: none;

        &:hover {
            color: var(--foreground-link);
            text-decoration: underline;
        }

        &:visited {
            color: var(--foreground-link-visited);
        }
    }

    ul {
        padding-left: spacing(large);
    }

    p + p,
    p + ul,
    ul + p {
        margin-top: spacing(x-small);
    }

    @include stack-component;
    @include layout-component;
    @include card-component;
    @include item-list-component;
    @include page-component;
    @include page-header-component;
    @include action-menu-component;
    @include alert-component;
    @include badge-component;
    @include form-actions-component;
    @include form-field-component;
    @include list-component;
    @include spinner-component;
}

@mixin platform-theme($theme) {
    //   @include test-component-theme($test-app-theme);
}
