@mixin list-component {
    .list-item__label,
    .list-item__content {
        padding: spacing(xx-small) 0;
        text-align: left;
        word-break: break-word;
    }

    .list-item__label {
        flex-basis: 30%;
        // font-weight: 600;
        color: var(--foreground-secondary);
    }

    .list-item__content {
        flex: 1;
    }

    .list--striped {
        .list-item__label,
        .list-item__content {
            padding: spacing(x-small) spacing(small);
        }

        .list-item:nth-child(odd) {
            background-color: pink;
        }
    }

    .list--label-small .list-item__label {
        flex-basis: 20%;
    }

    .list--label-large .list-item__label {
        flex-basis: 50%;
    }

    .list--label-x-large .list-item__label {
        flex-basis: 70%;
    }

    @include content-when-small() {
        .list-item {
            display: flex;
        }
    }
}
