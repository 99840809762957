.arrivals-wrapper {
    min-height: 1252px;
    background: #fff;
    background-image: url(/Content/images/abovetheclouds.jpg);
    background-repeat: repeat-x;
    background-position-x: center;
    background-size: 1500px 1300px;
}

.arrivals {
    font-family: -apple-system, 'SF UI Text', 'Helvetica Neue', sans-serif;
    font-size: 13px;
    line-height: 1.2;
    position: relative;
    z-index: 100;
    max-width: 810px;
    margin: 40px auto 100px;
    color: #666;
    border-radius: 10px;
    background: #333;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    -webkit-font-smoothing: antialiased;

    &__header {
        font-size: 28px;
        font-weight: 400;
        padding: 17px 20px 17px 70px;
        color: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        background-image: url('/assets/logos/arrivals-planelanding.png');
        background-repeat: no-repeat;
        background-position: 25px 18px;
        background-size: 30px 30px;
        text-shadow: 0 -1px 0 #000;

        h1 {
            font-size: 28px;
            margin: 0;
        }
    }

    &__header-icon {
        font-weight: normal;
        transform: rotateZ(75deg);
    }

    &__list {
        list-style: none;
    }

    &__footer {
        min-height: 30px;
        padding: 17px 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.04);
    }

    &__not-found {
        font-size: 18px;
        margin: 0;
        padding: 20px 30px;
        color: #888;
        border-top: 1px solid rgba(255, 255, 255, 0.04);
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    }

    .btn {
        color: #e0e0e0;
        border-color: #262626;
        background: #262626;

        &:hover {
            background: #000;
        }
    }

    @include break(tablet) {
        margin: 140px auto 100px;
    }
}

.arrival {
    @extend %clearfix;

    position: relative;
    padding: 17px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.04);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);

    &__title {
        float: left;
        width: 66.034%;
        margin-left: 2%;
        margin-left: 0;
    }

    &__status {
        float: left;
        width: 31.966%;
        margin-left: 2%;

        .arrival__flipbox {
            text-align: center;
        }
    }

    &__flipbox {
        font-size: 20px;
        font-weight: 700;
        line-height: 38px;
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        margin-bottom: 7px;
        padding: 0 10px;
        white-space: nowrap;
        color: #e0e0e0;
        border-radius: 3px;
        background: #1a1a1a;
        background: linear-gradient(to top, #212121 50%, #262626);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5) inset,
            0 -2px 0 rgba(0, 0, 0, 0.25) inset, 0 1px 0 rgba(255, 255, 255, 0.1);
        text-shadow: 0 -1px 0 #000;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: '';
            background: 0 0;
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.15) 49.99%,
                rgba(0, 0, 0, 0.6) 50%,
                rgba(0, 0, 0, 0) 54%,
                rgba(0, 0, 0, 0.05) 100%
            );
        }
    }

    &__caption {
        a {
            transition: color 0.15s ease-out;
            text-decoration: underline;

            &:hover {
                color: #eee;
            }
        }
    }

    .arrival__flipbox--arrived {
        color: #4eea82;
    }

    .arrival__flipbox--delayed {
        color: #efb350;
    }

    .arrival__flipbox--cancelled {
        color: #ff4d49;
    }
}

.arrival-form {
    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .arrivals__input {
        font-size: 14px;
        line-height: 16px;
        width: 100%;
        max-width: 100%;
        height: 38px;
        min-height: 38px;
        margin-bottom: 15px;
        padding: 10px 11px;
        color: #e0e0e0;
        border: 0;
        border-radius: 3px;
        outline: 0;
        background: #1a1a1a;
        background: linear-gradient(0deg, #212121 50%, #262626 100%);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5) inset,
            0 -2px 0 rgba(0, 0, 0, 0.25) inset, 0 1px 0 rgba(255, 255, 255, 0.1);
        text-shadow: 0 -1px 0 #000;

        &:focus {
            border: 0;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5) inset,
                0 -2px 0 rgba(0, 0, 0, 0.25) inset,
                0 1px 0 rgba(255, 255, 255, 0.1);
        }

        > option {
            color: #333;
        }
    }

    .arrivals__input-label {
        display: block;
        margin: 0 0 10px;
        color: #999;
    }
}
