%btn {
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    padding: $btn-vertical-padding $btn-horizontal-padding;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: $btn-border-width solid $btn-border-color;
    border-radius: $btn-border-radius;
    background: #fff;
    color: $btn-color;
    box-shadow: 0 2px 0 rgba(132, 139, 196, 0.05);
    appearance: none;

    // Button States
    //
    // States a button can have
    //
    // :hover - Highlights when hovering.
    // :active - Highlights when hovering.
    // :focus - Highlight when focusing.
    // .is-disabled - Disabled button state.
    //
    // Markup: <a href="#" class="btn btn--primary {{modifier_class}}">Link</a>
    // <a href="#" class="btn {{modifier_class}}">Link</a>
    //
    // Style guide: components.button.states
    &:hover,
    &:focus {
        background: $btn-background-color;
    }

    &:active {
        box-shadow: inset 0 1px 0 rgba(63, 65, 80, 0.05);
    }

    // &:focus {
    //     // box-shadow: 0 3px 0 rgba(64, 243, 248, 0.08);

    //     // outline: none;
    // }
}

// Buttons
//
// Your standard button suitable for clicking.
//
// Markup: <a href="#" class="btn {{modifier_class}}">Link</a>
// <button type="submit" class="btn {{modifier_class}}">Button</button>
// <input type="submit" class="btn {{modifier_class}}" value="Input:submit" />
// <input type="button" class="btn {{modifier_class}}" value="Input:button" />
//
// Style guide: components.button
.btn:not([pButton]) {
    @extend %btn;

    // Button Colours
    //
    // Your standard button suitable for clicking.
    //
    // .btn--default - Default button styles.
    // .btn--primary - Primary button styles.
    // .btn--success - Success button styles.
    // .btn--info - Informative button styles.
    // .btn--error - Error button styles.
    // .btn--warn - Warning button styles.
    // .btn--extra - Extra button styles.
    // .btn--link - Plain link button.
    //
    // Markup: <a href="#" class="btn {{modifier_class}}">Link</a>
    // <button type="submit" class="btn {{modifier_class}}">Button</button>
    // <input type="submit" class="btn {{modifier_class}}" value="Input:submit" />
    // <input type="button" class="btn {{modifier_class}}" value="Input:button" />
    //
    // Style guide: components.button.colors
    &.btn--primary,
    &.btn--success,
    &.btn--info,
    &.btn--error,
    &.btn--warn,
    &.btn--extra {
        color: #fff;
    }

    &.btn--default {
        border-color: $default;
        background: $default;

        &:hover,
        &:focus {
            border-color: darken($default, 5%);
            background: darken($default, 5%);
        }
    }

    &.btn--primary {
        border-color: $primary;
        background: $primary;

        &:hover,
        &:focus {
            border-color: darken($primary, 5%);
            background: darken($primary, 5%);
        }
    }

    &.btn--success {
        border-color: $success;
        background: $success;

        &:hover,
        &:focus {
            border-color: darken($success, 5%);
            background: darken($success, 5%);
        }
    }

    &.btn--info {
        border-color: $info;
        background: $info;

        &:hover,
        &:focus {
            border-color: darken($info, 5%);
            background: darken($info, 5%);
        }
    }

    &.btn--error {
        border-color: $error;
        background: $error;

        &:hover,
        &:focus {
            border-color: darken($error, 5%);
            background: darken($error, 5%);
        }
    }

    &.btn--warn {
        border-color: $warm;
        background: $warm;

        &:hover,
        &:focus {
            border-color: darken($warm, 5%);
            background: darken($warm, 5%);
        }
    }

    &.btn--extra {
        border-color: $extra;
        background: $extra;

        &:hover,
        &:focus {
            border-color: darken($extra, 5%);
            background: darken($extra, 5%);
        }
    }

    &[disabled],
    &.is-disabled {
        cursor: not-allowed;
        opacity: 0.6;
        // box-shadow: none;

        &:hover,
        &:focus {
            border-color: $btn-border-color;
            background: #fff;

            &.btn--default {
                border-color: $default;

                background: $default;
            }

            &.btn--primary {
                border-color: $primary;
                background: $primary;
            }

            &.btn--success {
                border-color: $success;
                background: $success;
            }

            &.btn--info {
                border-color: $info;
                background: $info;
            }

            &.btn--error {
                border-color: $error;
                background: $error;
            }

            &.btn--warn {
                border-color: $warm;
                background: $warm;
            }

            &.btn--extra {
                border-color: $extra;
                background: $extra;
            }

            &.btn--link {
                text-decoration: none;
                border-color: transparent;
                background: transparent;
            }
        }
    }
}

.btn--link:not([pButton]) {
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    // color: $link;
    // color: #69a2df;

    &:hover,
    &:focus {
        text-decoration: underline;
        border-color: transparent;
        box-shadow: none;
        background: transparent;
        color: $primary;
    }

    &.btn--link-alt {
        &:hover,
        &:focus {
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
            border-color: darken($default, 5%);
        }
    }
}

// Button Sizes
//
// Your standard button suitable for clicking.
//
// .btn--extra-small - Primary button styles.
// .btn--small - Success button styles.
// .btn--large - Informative button styles.
// .btn--block - Informative button styles.
//
// Markup: <a href="#" class="btn btn--primary {{modifier_class}}">Link</a>
// <a href="#" class="btn {{modifier_class}}">Link</a>
//
// Style guide: components.button.sizes
.btn--extra-small {
    font-size: decimal-round($btn-font-size/1.4, 2);
    padding: decimal-round($btn-vertical-padding/5, 2)
        decimal-round($btn-horizontal-padding/2, 2);
}

.btn--small {
    font-size: decimal-round($btn-font-size/1.2, 2);
    padding: decimal-round($btn-vertical-padding/2, 2)
        decimal-round($btn-horizontal-padding/1.8, 2);
}

.btn--small-thin {
    font-size: 12px;
    padding: 4px 10px;
}

.btn--large {
    padding: 15px 30px;
}

.btn--block {
    display: block;
    width: 100%;
}

.btn--icon {
    padding-left: $btn-vertical-padding;
    padding-right: $btn-vertical-padding;
}

.btn--block + .btn--block {
    margin-top: 7px;
}

.btn--dropdown {
    .btn__label {
        margin-right: 4px;
    }

    .btn__select {
        width: auto;
        margin-right: -6px;

        .mat-mdc-select-value {
            max-width: 150px;
        }
    }
}

// Button Groups
//
// Class to wrap a collection of buttons within.
//
// .btns--horizontal - Horizontal collection of buttons.
// .btns--centered - Centered collection of buttons within btns.
// .btns--small - Small collection of buttons.
//
// Markup: <div class="btns {{modifier_class}}">
//      <a href="#" class="btn btn--primary">Link</a>
//      <a href="#" class="btn">Link</a>
// </div>
//
// Weight: 1
//
// Style guide: components.button.groups
.btns {
    display: flex;
    flex-direction: column;
    // @extend %clearfix;

    &.btns--small {
        .btn {
            @extend .btn--small;
        }
    }

    &.btns--small-thin {
        .btn {
            @extend .btn--small-thin;
        }
    }

    .btns__divider {
        flex: 1;
    }
}

.btns--horizontal {
    flex-direction: row;
    flex-wrap: wrap;

    &.btns--right {
        justify-content: flex-end;
    }

    .btn--right {
        justify-self: flex-end;
    }
}

.btns--centered {
    text-align: center;

    .btn {
        display: inline-block;
    }
}

.btns--horizontal,
.btns--centered {
    margin-left: -0.4em;
    margin-top: -0.4em;

    .btn {
        width: auto;
        margin: 0.4em 0 0 0.4em;

        // &.btn--right {
        //     float: right;
        //     margin: 0 0 0 .4em;
        // }

        // &.btn--left {
        //     float: left;
        // }
    }
}

.btns--group {
    @extend .btns--horizontal;

    .btn {
        margin: 0;
    }

    .btn:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }
}

.btn--arrow {
    padding: 10px 5px;
}
