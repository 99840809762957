.dialog {
    .ngdialog-content {
        position: relative;
        width: 98%;
        min-height: 32px;
        margin: 0 auto;
        background: #fff;
        box-shadow: 0 2px 26px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.1);
    }

    .dialog__header {
        padding: 35px 28px 25px;

        &--inline {
            display: flex;
            align-content: center;
        }

        .dialog__heading {
            font-size: 26px;
            margin: 0;
        }
    }

    .dialog__message {
        margin-bottom: 14px;
    }

    .dialog__alert {
        font-size: 0.9em;
        padding: 10px 15px;
        background: #f9f9f9;

        &--warn {
            color: $white;
            background: $warm;
        }

        &--error {
            color: $error;
            background: #fde8e8;
        }
    }

    .dialog__btns {
        padding: 25px 28px 14px;
    }

    .dialog__divider {
        display: block;
        height: 1px;
        margin: 14px 0;
        background: #f5f5f5;
    }

    .dialog__sub-message {
        font-size: 14px;
        margin-bottom: 10px;
        color: #7b828a;
    }

    &.dialog--large {
        .ngdialog-content {
            @include break(tablet) {
                width: 1000px;
            }
        }
    }
}

.dialog__sub-heading {
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 17px;
}

.dialog .ngdialog-close,
.dialog-close {
    font-size: 22px;
    line-height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    border: 0;
    background: transparent;
    appearance: none;

    &:hover {
        color: #000;
        background: #e6f3fb;
    }
}

.dialog--feedback {
    height: 65vh;

    bug-report-dialog,
    .mat-mdc-dialog-content {
        height: 100%;
    }

    .mat-mdc-dialog-container {
        background-color: #f8f9fc;
    }

    .mat-mdc-dialog-content {
        display: flex;
        align-items: stretch;
    }

    iframe {
        border: none;
        max-width: 100%;
        width: 500px;
    }
}

.dialog-selection {
    &__item {
        position: relative;
        border-bottom: 1px solid lighten(#e7ecf3, 5%);

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: lighten(#e7ecf3, 5%);
        }
    }

    &__link {
        display: block;
        padding: 20px 30px;
        padding-right: 60px;
    }

    &__title {
        font-size: 20px;
    }

    &__details {
        font-size: 12px;
        line-height: 20px;
        padding: 5px 0;
        color: #aaa;
    }

    &__chevron {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
    }
}

.mat-mdc-dialog-content .dialog-selection__item {
    margin: 0 -24px;
}

@include break(tablet) {
    .ngdialog {
        z-index: 1001;
        background: rgba(170, 170, 170, 0.3);
    }

    // the overlay is actually useless

    /*
        .ngdialog .ngdialog-overlay {
        right: 20px;
        background: transparent;
        }
    */

    // then give some size to the dialog content and put it in the middle
    .ngdialog .ngdialog-content {
        position: relative;
        width: 600px;
        max-width: 97%;
        margin: 5% auto;
    }
}

.ngn {
    z-index: 2000;
}

.cdk-overlay-container {
    .mat-mdc-dialog-container {
        box-shadow: 0 2px 26px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.1);
    }

    .cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
        background: rgba(171, 171, 171, 0.3);
    }

    .cdk-overlay-backdrop.cdk-overlay-solid-backdrop {
        background: rgba(59, 58, 63, 0.93);
    }
}

.image-preview-overlay {
    max-width: 90%;
    max-width: calc(100% - 200px);
}

.cdk-overlay-pane.dialog .mat-mdc-dialog-container {
    position: relative;
}

.dialog-close:before {
    font-family: 'Helvetica', Arial, sans-serif;
    content: '\D7';
    cursor: pointer;
}

.dialog {
    .mat-mdc-dialog-actions {
        justify-content: flex-end;
        padding: 12px 0;
    }

    .mat-mdc-dialog-actions[align='start'] {
        justify-content: flex-start;
    }
}
