.incident-item__images {
    min-height: 150px;
}

.incident-image {
    position: relative;
    margin: 0 5px 5px 0;

    .incident-image__link {
        display: inline-block;
        background-size: cover;
        background-color: #f9f9f9;
    }

    .incident-image__image {
        display: block;
        height: 150px;
        cursor: move;
    }

    .incident-image__remove {
        font-size: 0.8em;
        position: absolute;
        top: 0;
        right: 0;
        padding: 6px;
        transition: all 0.3s;

        &:hover {
            background: #fafafa;
        }
    }

    .incident-image__type {
        left: 0;
    }

    .incident-image__delete {
        right: 0;
        transition: all 0.3s;

        &:hover {
            background-color: white;
        }
    }

    .incident-image__type,
    .incident-image__delete {
        position: absolute;
        top: 0;
        padding: 6px 10px;
        font-size: 0.8em;
        background: rgba(248, 248, 248, 0.274);
    }
}
