.user-thumbnail {
    display: inline-block;
    width: 64px;
    height: 64px;

    &.user-thumbnail--medium {
        width: 48px;
        height: 48px;
    }

    &.user-thumbnail--small {
        width: 24px;
        height: 24px;
    }

    &.user-thumbnail--circle {
        border-radius: 50%;
    }
}
