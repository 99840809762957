.customer-card {
    position: relative;
    margin-bottom: 14px;
    padding: 14px;
    border: 1px solid #e2e7e7;

    .customer-card__name {
        margin-bottom: 7px;
    }

    .customer-card__sub {
        font-size: 0.7em;
    }

    .customer-card__actions {
        @extend .list-horizontal;

        font-size: 0.8em;
        position: absolute;
        top: 0;
        right: 0;
    }

    .customer-card__actions-link {
        display: block;
        padding: 8px;
        transition: all 0.3s;

        &:hover {
            background: #fafafa;
        }
    }
}
