.dropdown {
    position: relative;

    &__list {
        font-size: 14px;
        position: absolute;
        z-index: 1000;
        top: 100%;
        left: 0;
        display: none;
        min-width: 160px;
        margin: 2px 0 0;
        padding: 5px 0;
        list-style: none;
        text-align: left;
        border: none;
        border: 1px solid #e2e7e7;
        border-radius: 2px;
        background: #fff;
        box-shadow: 0 1px 3px rgba(70, 82, 98, 0.25);
    }

    &__link {
        font-weight: 400;
        line-height: $line_height;
        display: block;
        clear: both;
        padding: 3px 20px;
        white-space: nowrap;
        outline: none;

        &:hover {
            background: #f9f9f9;
        }
    }

    &__backdrop {
        position: fixed;
        z-index: 990;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .dropdown__item--selected .dropdown__link {
        color: #fff;
        background: saturate($labs_blue, 20%);
    }
}

.dropdown--open {
    > .dropdown__list {
        display: block;
    }
}
