.fatal-error {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 500px;
    padding: 3em 0;
    animation: animatedBackground 14s ease-in;
    color: #fff;
    background: #00aff9;
    background-image: url(/content/images/crash-vehicle.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 846px 250px;
    animation-fill-mode: forwards;

    a {
        font-weight: bold;
        text-decoration: underline;
        color: #fff;

        &:hover {
            color: #666;
        }
    }

    .fatal-error--heading {
        font-size: 3em;
        margin: 0.8em 30px;

        .fatal-error--heading-logo {
            margin-right: 20px;
        }
    }

    .fatal-error--message {
        font-size: 1.3em;
        display: inline-block;
        margin: 0.2em 30px;
    }

    .fatal-error--image {
        position: absolute;
        right: -150px;
        bottom: 0;
        height: 250px;
    }
}

@keyframes animatedBackground {
    0% {
        background-position: bottom right;
    }

    100% {
        background-position: -1000px bottom;
    }
}
