.bookings-customers-list {
    .bookings-customers-list__item {
        position: relative;
    }

    .bookings-customers-list__actions {
        font-size: 12px;
        line-height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
    }
}

.booking-customer-profile {
    position: relative;
    //height: 250px;
    background: #edf1f6;

    .booking-customer-profile__details {
        display: flex;
        padding: 40px 0;
    }

    .booking-customer-profile__image {
        width: 120px;
        height: 120px;
        margin-right: 40px;
    }

    .booking-customer-profile__info {
        flex-grow: 1;
    }

    .booking-customer-profile__name {
        font-size: 38px;
        margin: 0;
        padding: 37px 0;
    }

    .booking-customer-profile__actions {
        font-size: 0.8em;
        align-self: center;
    }
}

.booking-customer-contact {
    address {
        font-style: normal;
    }
}

.booking-customer-search {
    .booking-customer-search__title {
        font-size: 28px;
        display: none;
        margin: 20px auto 28px;
        cursor: pointer;
    }

    &.booking-customer-search--large {
        .booking-customer-search__title {
            display: block;
        }

        .content-list-toolbar__item {
            display: block;
            float: none;
            text-align: center;
        }

        .content-list-toolbar__input {
            max-width: 500px;
            margin: 0 auto;
        }
    }
}
