@mixin form-actions-space($index: small) {
    margin-top: spacing($index) * -1;
    margin-left: spacing($index) * -1;

    > * {
        margin-left: spacing($index);
        margin-top: spacing($index);
    }
}

@mixin form-actions-component {
    .form-actions {
        display: block;
            display: block;
    }

    .form-actions--primary {
        @include layout-spaced();
    }

    .form-actions .form-actions__wrapper {
        display: flex;
        flex-direction: column;
    }

    .form-actions .form-actions__wrapper {
        @include form-actions-space(x-small);
        justify-content: flex-end;
    }

    @include content-when-medium {
        .form-actions .form-actions__wrapper {
            flex-direction: row;
            align-items: center;

            @include form-actions-space();
        }
    }
}
