@mixin list-item {
    padding: spacing();

    @include content-when-medium() {
        padding: spacing() spacing(large);
    }
}

@mixin item-list-component {
    .item-list__controls {
        @include list-item;

        display: flex;
    }

    .item-list__header {
        flex: 1 1 auto;
        align-self: center;
    }

    .item-list__controls {
        border-bottom: border-width() solid var(--border-lightest);
    }

    .item-list__select-controls {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .item-list-item {
        border-bottom: border-width() solid var(--border-lightest);
    }

    .item-list--borderless .item-list-item,
    .item-list:last-child .item-list-item:last-child {
        border-bottom-width: 0;
    }

    .item-list-item {
        @include list-item;

        display: block;
        position: relative;
    }

    .item-list__link {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: transitions-fast(background-color);

        &:hover {
            background-color: var(--background-light-card);
        }
    }

    .item-list-item__prefix,
    .item-list__select-all {
        z-index: 2;
        margin-right: spacing();
    }

    .item-list__select-all {
        min-height: rem(30px);
        display: flex;
        align-items: center;
    }

    .item-list-item__wrapper {
        display: flex;
    }

    .item-list-item__content {
        z-index: 2;
        pointer-events: none;
        flex: 1 1 auto;
        align-self: center;

        a {
            pointer-events: initial;
        }
    }

    .item-list__spinner {
        padding: spacing(xxx-large) spacing();
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .list-item__empty-default {
        padding: spacing(large) spacing();
        text-align: center;
        color: var(--foreground-secondary);
    }
}
