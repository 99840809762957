.pager {
    margin: 20px 10px 30px;
    text-align: center;

    .pager__pages {
        margin-bottom: 10px;
    }
}

.simple-pager {
    padding: 20px;
    text-align: center;
    color: #91a0ad;

    &__count {
        line-height: 40px;
        display: inline-block;
        min-width: 40px;
        height: 40px;
        padding: 0 10px;
        text-align: center;
    }

    &__back,
    &__next {
        line-height: 40px;
        display: inline-block;
        min-width: 40px;
        height: 40px;
        padding: 0 10px;
        cursor: pointer;
        text-align: center;
        border: 1px solid darken(#f8f9fc, 5%);
        border-radius: 1px;
        background: #fff;

        &[disabled] {
            cursor: not-allowed;
            background: lighten(#e7ecf3, 5%);
        }
    }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix, .mat-mdc-text-field-wrapper.mdc-text-field--no-label .mat-mdc-form-field-infix{
    padding-top: 8px;
    padding-bottom: 8px;
}

.mat-mdc-tooltip{
    background-color: black;
    color: white;
    border-radius: 10px;
}