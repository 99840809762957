.mat-mdc-slider {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 4px;
    margin: 10px 0 15px;
    vertical-align: middle;
}

.mat-mdc-slider-labelled {
    padding: 12px 10px;
    position: relative;

    .mat-mdc-slider-thumb-label {
        transform: rotate(45deg) !important;
        border-radius: 50% 50% 0 !important;
    }

    .mat-mdc-slider-thumb {
        transform: scale(0) !important;
    }

    .mat-mdc-slider-thumb-label-text {
        opacity: 1 !important;
        color: #fff;
    }
}

.rzslider {
    height: 8px;

    .rz-pointer {
        top: -6px;
        width: 19px;
        height: 19px;
        transition: background 0.4s, box-shadow 0.4s;
        border: 1px solid #ccc;
        border-radius: 50%;
        background: #fff;
        box-shadow: 1px 1px 2px #ddd;

        &:after {
            display: none;
        }

        &:hover {
            outline: none;
            background: #f8f8f8;
            box-shadow: 1px 1px 2px #aaa;
        }

        &.rz-active {
            outline: none;
        }
    }

    .rz-bar {
        height: 8px;
        border-radius: 4px;
        background: #f8f8f8;
    }

    .rz-bubble {
        color: inherit;
    }

    .rz-bubble.rz-limit {
        font-size: 0.8em;
        color: #999;
    }
}

.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator-container {
    margin-left: -1.85rem;
}