$breakpoints: (
    small: 470px,
    medium: 720px,
    large: 920px,
    x-large: 1025px,
    xx-large: 1250px,
    xxx-large: 1650px,
);

@function breakpoint($variant, $adjustment: 0) {
    $value: map-get($breakpoints, $variant);

    @if type-of($value) != number {
        @error 'Breakpoints variant `#{$variant}` not found. Available variants: #{available-names($border-width-data)}';
    }

    $adjusted-value: em($adjustment);

    @if $adjustment == -1px {
        $adjusted-value: -0.01em;
    } @else if $adjustment == 1px {
        $adjusted-value: 0.01em;
    }

    @return em($value) + $adjusted-value;
}

@mixin breakpoint-after($breakpoint, $inclusive: true) {
    @media (min-width: #{breakpoint($breakpoint, if($inclusive, 0, 1px))}) {
        @content;
    }
}

@mixin breakpoint-before($breakpoint, $inclusive: false) {
    @media (max-width: #{breakpoint($breakpoint, if($inclusive, 0, 1px))}) {
        @content;
    }
}

@mixin content-when-small() {
    @include breakpoint-after(small) {
        @content;
    }
}

@mixin content-when-medium() {
    @include breakpoint-after(medium) {
        @content;
    }
}

@mixin content-when-large() {
    @include breakpoint-after(large) {
        @content;
    }
}
