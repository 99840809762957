$tariff-rules-connector-width: 3px;

.tariff-rule {
    background: #fff;
    position: relative;
    border-bottom: 1px solid #ececec;

    .tariff-rule__message {
        padding: 9px 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .tariff-rule__value,
    .tariff-rule__subject,
    .tariff-rule__verb {
        display: inline-block;
        margin: 0 4px;
        vertical-align: middle;
    }

    span.tariff-rule__value,
    span.tariff-rule__subject,
    span.tariff-rule__verb {
        font-size: 0.9em;
        display: inline-block;
        min-width: 35px;
        padding: 10px 12px;
        line-height: 16px;
        text-align: center;
    }

    span.tariff-rule__value {
        border: 1px solid #e2e7e7;
        border-radius: 0;
    }

    span.tariff-rule__subject {
        border: 1px solid $warm;
    }

    span.tariff-rule__verb {
        text-align: center;
        border: 1px solid #f5f5f5;
        border-radius: 11px;
    }

    .tariff-rule__value {
        vertical-align: middle;
        margin: 0 4px;
    }

    .tariff-rule__subject.input-select {
        .mat-mdc-select-trigger {
            border: 1px solid $warm;
        }
    }

    .input-select {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .input-select .mat-mdc-select-trigger,
    input {
        width: 130px;
    }

    .input-datetime-picker .input-select {
        width: 90px;
    }

    .tariff-rule__remove {
        font-size: 12px;
        line-height: 23px;
        display: inline-block;
        width: 23px;
        margin-right: 10px;
        transition: all 0.3s;
        text-align: center;
        color: #b6b6b6;
        border: 1px solid #f0f0f0;
        border-radius: 50%;

        &:hover {
            color: $error;
            border-color: $error;
        }
    }

    .tariff-rule__content {
        padding: 10px 10px 10px 50px;
    }

    .tariff-rules__list {
        > div {
            position: relative;
            margin: 0;
            padding: 0 0 0 38px;

            &:before {
                font-size: 10px;
                line-height: 14px;
                position: absolute;
                z-index: 10;
                top: 28px;
                left: 9px;
                width: 22px;
                padding: 2px 3px;
                content: 'and';
                transform: translateY(-50%);
                text-align: center;
                color: #68d598;
                background: #c7fecb;
            }

            &:after {
                position: absolute;
                top: 0;
                left: 18px;
                width: $tariff-rules-connector-width;
                height: 100%;
                content: '';
                background: #e2e2e2;
            }

            &:last-child:after {
                height: 28px;
            }
        }
    }
}

.ng-submitted .tariff-rule.ng-invalid,
.tariff-rule.ng-invalid.ng-touched {
    border-color: $error;
}

.ng-submitted .tariff-rule.ng-invalid .tariff-rule,
.tariff-rule.ng-invalid.ng-touched .tariff-rule {
    border-left: 1px solid $error;
}

.tariff-rule__collection {
    padding: 0 0 0 50px;
    border-top: 1px solid #ececec;
    background: $input-background-color;

    &:not(.tariff-rule__collection--display) .tariff-rules__list {
        min-height: 100px;
        padding-bottom: 50px;
    }

    .tariff-rule {
        border-left: 1px solid #ececec;
    }

    .tariff-rule:after {
        content: '';
        position: absolute;
        left: -20px;
        height: $tariff-rules-connector-width;
        top: 28px;
        width: 20px;
        background: #e2e2e2;
        transform: translateY(-50%);
    }
}

.tariff-rule__validation-input {
    display: none;
}

.rules-add__root-list {
    > .tariff-rules__list {
        position: relative;
        padding-bottom: 35px;

        &:empty:before {
            position: absolute;
            top: 50%;
            left: 50%;
            content: 'Drag rules here to get started.';
            transform: translateX(-50%) translateY(-50%);
            color: #6a6a6a;
        }
    }
}

.rules-add__outcome {
    > .tariff-rules__list {
        position: relative;
        min-height: 100px;
        padding-bottom: 35px;
    }
}

.tariff-rule--collection-and {
    > .tariff-rule__message > .tariff-rule__subject {
        color: #68d598;
        border-color: #68d598;
        background: #c7fecb;
    }
}

.tariff-rule--collection-or {
    > .tariff-rule__message > .tariff-rule__subject {
        color: #e6c816;
        border-color: #e6c816;
        background: #fced94;
    }
    // This is horrible.
    > .tariff-rule__collection > tariff-rules-list > .tariff-rules__list {
        > div {
            &:before {
                content: 'or';
                color: #e6c816;
                background: #fced94;
            }
        }
    }
}

.tariff-rule-dates {
    max-width: 29px * 7;

    .tariff-rule-dates__date {
        line-height: 27px;
        display: inline-block;
        width: 27px;
        height: 27px;
        margin: 1px;
        text-align: center;
        border: 1px solid #ececec;
    }

    .tariff-rule-dates__date--selected {
        color: #fff;
        border-color: $success;
        background: $success;
    }
}

.tariff-result {
    max-width: 300px;
    margin: 40px auto 0;
    border: 1px solid #e2e7e7;
    background: #fff;

    .tariff-result__header {
        padding: 5px 10px;
    }

    .tariff-result__heading {
        display: inline-block;
        padding: 6px;
    }

    .tariff-result__value {
        width: 75px;
        height: 35px;
    }

    .tariff-result__message {
        text-align: center;
    }
}

.rules-add__rules {
    background: $input-background-color;

    .rules-add__root-list {
        > .tariff-rules__list {
            min-height: 500px;
        }
    }

    .rules-add__outcome {
        > .tariff-rules__list {
            min-height: 250px;
        }
    }
}

.rules-add-container {
    user-select: none;

    /* margin: 20px 0; */
    align-items: stretch;
}

.rules-add-container--then {
    .rules-add__rules-heading-section {
        border-top: 1px solid #ececec;
    }
}

.rules-add__sidebar {
    position: relative;
    max-width: 250px;
    border-right: 1px solid #ececec;
}

.rules-add__sidebar-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    .rules-add__sidebar-input {
        padding: 10px 15px;
        flex-shrink: 0;
    }

    .rules-add__sidebar-scroll {
        overflow: auto;
        padding: 0 15px 10px;
        flex-shrink: 1;
        flex-grow: 1;
    }
}

.rules-add__sidebar-templates {
    overflow-y: auto;
    list-style: none;
}

.rules-add__sidebar-heading {
    font-size: 16px;
    padding: 5px 15px;
    color: #8e939c;
}

.rules-add__template {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #e2e7e7;
    border-radius: 3px;
    background: #fff;

    .rules-add__template-description {
        font-size: 12px;
        margin-top: 7px;
        color: #8e939c;
    }
}

.rules-add__rules-heading-section {
    position: relative;
    border-bottom: 1px solid #ececec;
    background: #fff;
}

.rules-add__rules-heading {
    font-size: 12px;
    line-height: 46px;
    position: relative;
    display: inline-block;
    // color: white;
    width: 46px;
    margin: 20px 5px 20px 60px;
    text-align: center;
    border-radius: 23px;
    background: lighten(#ececec, 5%);

    &:after {
        position: absolute;
        top: 100%;
        left: 21px;
        width: 4px;
        height: 20px;
        content: ' ';
        background: #ececec;
    }
}

.rules-add__rules-btn {
    float: right;
    margin: 24px;
}

.rules-add__rules-heading--then {
    color: #fff;
    background: $success;

    &:before {
        position: absolute;
        top: -20px;
        left: 21px;
        width: 4px;
        height: 20px;
        content: ' ';
        background: #ececec;
    }
}

.tariff-rule__actions {
    font-size: 0.8em;
    margin-left: auto;

    .tariff-rule__action {
        color: #fff;
        border-radius: 3px;
        background: #b6cad5;
        display: block;
        padding: 5px 7px;
        text-align: center;
        border: 1px solid #b6cad5;
    }

    .tariff-rule__action--inactive {
        display: none;
    }
}

.tariff-rule--inactive {
    opacity: 0.6;

    &,
    .tariff-rule,
    .tariff-rule__collection {
        background: #f9f9f9;
    }

    > .tariff-rule__message {
        .tariff-rule__actions .tariff-rule__action {
            color: #b6cad5;
            background: transparent;
        }

        .tariff-rule__action--active {
            display: none;
        }

        .tariff-rule__action--inactive {
            display: block;
        }
    }

    .tariff-rule__collection .tariff-rule__actions {
        display: none;
    }
}

.rules_groups {
    .tariff-group-selection__groups {
        overflow-y: auto;
        max-height: 300px;
    }
}

.tariff-rules__list .tariff-rule-placeholder {
    background: #ececec;
    min-height: 50px;
    opacity: 1;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

    &:before,
    &:after {
        display: none;
    }
}
