@mixin card-component {
    .card {
        display: block;
        border-radius: border-radius(large);
        background: var(--background-card);
        box-shadow: var(--shadow-small);

        // This is to catch inner elements with background color that may overflow
        // the card.
        // TODO: ensure this is the best way to achive this.
        overflow: hidden;
    }

    .card + .card,
    .alert + .card {
        margin-top: spacing(large);
    }

    .card--flat {
        box-shadow: none;
    }

    .card--secondary,
    .card__section--secondary,
    .card__meta-data {
        background-color: var(--background-light-card);
    }

    .card__header {
        padding: spacing() spacing() spacing(small);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: border-width() solid var(--border-lightest);

        @include content-when-medium() {
            padding: spacing(large) spacing(large) spacing();
        }
    }

    .card__title {
        font-size: font-size();
        font-weight: font-weight();
        flex: 1 auto;
    }

    .card__section--secondary + .card__footer,
    .card__section + .card__section {
        border-top: 1px solid var(--border-lightest);
    }

    // .card__section--secondary:last-child,
    // .card__meta-data {
    //     border-bottom-left-radius: border-radius(large);
    //     border-bottom-right-radius: border-radius(large);
    // }

    .card__section--secondary + .card__footer {
        padding: spacing();

        @include content-when-medium() {
            padding: spacing(large);
        }
    }

    .card__footer {
        padding: 0 spacing() spacing();
        justify-content: flex-end;
        display: flex;

        @include content-when-medium() {
            padding: 0 spacing(large) spacing(large);
            align-items: center;
        }
    }

    .card__meta-data {
        padding: spacing();
        font-size: font-size(small);
        line-height: line-height(small);

        @include content-when-medium() {
            padding: spacing(large);
            align-items: center;
        }
    }

    .card__sub-title,
    .card__actions {
        margin-top: spacing(xx-small);
        text-align: right;
    }

    .card__actions {
        width: 100%;
    }

    @include content-when-medium() {
        .card__sub-title,
        .card__actions {
            margin-left: spacing();
            width: auto;
            margin-top: 0;
        }
    }

    // .card__section-title {
    //     font-size: font-size(small);
    // }

    // material overrides
    .card .mat-mdc-paginator {
        background-color: transparent;
    }

    .card .mat-mdc-paginator-container {
        padding: spacing(x-small) spacing();
    }
}
