.masonry-brick {
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .widget {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

// .widget {
//     border-radius: 3px;
//     background: #FFF;
//     box-shadow: 0 1px 2px #D8DBE3;
// }

// .widget__content {
//     padding: 10px 15px;
//     flex: 1;
// }

.widget__content--scrollable {
    @extend .simple-scroller;

    overflow-y: auto;
}

.widget__dropdown {
    margin-right: 5px;
}

.revenue-date__edit {
    padding-right: 10px;
}

.revenue-date-container {
    display: flex;
    align-items: center;
}

.revenue-widget__arrows {
    display: flex;
    overflow: hidden;
    border: 1px solid #eee;
    border-radius: 5px;
}

.revenue-widget__arrow {
    font-size: 18px;
    padding: 6px 7px;

    &:hover {
        background: #f9f9f9;
    }

    &.revenue-widget__arrow--disabled {
        cursor: not-allowed;
        color: lighten(#777, 20%);
        background: #f9f9f9;
    }
}

.revenue-widget__date {
    font-size: 15px;
    line-height: 20px;
    display: inline-block;
    margin-right: 10px;
}

.revenue-widget__no-results {
    font-size: 12px;
    margin: 10px;
    text-align: center;
}

.revenue-targets {
    width: 80%;
    min-width: 256px;
    margin: 25px auto 0;
}

.revenue-target {
    margin: 7px 0;
}

.revenue-target__title {
    font-size: 16px;
}

.revenue-target__ammount {
    font-size: 20px;
}

.revenue-target__percentage {
    font-size: 14px;
}

.revenue-chart--key {
    padding-top: 28px;
}

.revenue-day-widget {
    display: flex;
    justify-content: center;

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__desc {
        font-size: 12px;
        color: #888;
        //text-align: center;
    }

    &__days {
        font-size: 6em;
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
    }

    &__great {
        font-weight: bold;
        color: #496392;
    }

    .widget__dropdown {
        margin-bottom: 10px;
    }
}

.sales-performance__value {
    font-weight: 600;
}

.sales-performance__title {
    font-weight: 500;
    display: block;
    margin: 5px 0;
}

.dashboard-leaderboard__ribbon {
    position: relative;
    top: -7px;
    left: 5px;
    float: left;
    width: 22px;
    background: #ffb900;
}

.dashboard-leaderboard__ribbon > div {
    font-size: 13px;
    padding: 10px 0 8px;
}

.dashboard-leaderboard__ribbon:before {
    position: absolute;
    right: -4px;
    width: 0;
    height: 0;
    content: '';
    border-right: 4px solid transparent;
    border-bottom: 6px solid #e49700;
}

.dashboard-leaderboard__ribbon:after {
    position: absolute;
    bottom: -11px;
    left: 0;
    width: 0;
    height: 0;
    content: '';
    border-right: 11px solid #ffb900;
    border-bottom: 11px solid transparent;
    border-left: 11px solid #ffb900;
}

.dialog__header--targets {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.performance-target {
    margin-bottom: 10px;
}

.rent28-section {
    font-size: 12px;
    display: flex;
    overflow: hidden;
    margin: 10px 0;
    color: #fff;
    border-radius: 3px;
    box-shadow: 1px 1px 5px #ccc;
}

.rent28-section__branch-title {
    padding: 8px 15px 4px;
    background: #2ecc71;
    flex: 1;
}

.rent28-section__user-title {
    padding: 8px 15px 4px;
    background: #ff613b;
    flex: 1;
}

.rent28-section__branch-value {
    line-height: 29px;
    display: inline-block;
    padding: 0 15px;
    background: #2ca660;
}

.rent28-section__user-value {
    line-height: 29px;
    display: inline-block;
    padding: 0 15px;
    background: #dc5a3a;
}

.arrivals.arrivals--widget {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 3px;
    flex: 1;

    .arrivals__header--widget {
        font-size: 18px;
        padding: 10px 20px 10px 60px;
        background-position: 25px 12px;
        background-size: 22px 22px;

        h4 {
            margin: 0;
        }
    }

    .arrivals__list {
        flex-grow: 1;
    }
}

.arrivals__link {
    display: flex;
    flex: 1;
}

.arrival.arrival--widget {
    padding: 7px 20px;

    .arrival__title--widget {
        float: none;
        width: 100%;
    }

    .arrival__flipbox--widget {
        font-size: 13px;
        line-height: 25px;
        position: relative;
        border-radius: 3px;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: '';
            background: 0 0;
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.15) 49.99%,
                rgba(0, 0, 0, 0.6) 50%,
                rgba(0, 0, 0, 0) 54%,
                rgba(0, 0, 0, 0.05) 100%
            );
        }
    }

    .arrivals__flipbox-status {
        position: absolute;
        top: 16px;
        right: 30px;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: #fff;

        &--arrived {
            background: #4eea82;
        }

        &--delayed {
            background: #efb350;
        }

        &--cancelled {
            background: #ff4d49;
        }

        &--inbeta {
            background: #fffa00;
        }

        &--planning {
            background: #9a12b3;
        }

        &--indevelopment {
            background: #435a84;
        }
    }
}

@include break(tablet) {
    .widget__header {
        display: flex;
        user-select: none;
        justify-content: space-between;
    }
}
