.insurance-risk {
    margin-bottom: 25px;
    padding: 10px;
    border: 1px solid darken(#e7ecf3, 5%);
    border-radius: 3px;

    .insurance-risk__score {
        font-size: 40px;
    }

    .insurance-risk__calculating {
        font-size: 12px;
        margin: 10px 0 0;
        color: #a9a9a9;
    }
}

.additional-drivers-list {
    .additional-drivers-list__item {
        .additional-drivers-list__item-title {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #f8f9fc;
        }
    }

    .additional-drivers-list__item + .additional-drivers-list__item {
        margin-top: 14px;
        padding-top: 14px;
        border-top: 1px solid #f8f9fc;
    }
}

.approval-drivers-list {
    .approval-drivers-list__item {
        display: flex;
        padding: 7px;
    }

    .approval-drivers-list__label {
        font-size: 0.9em;
        padding: 4px 0;
        flex-grow: 1;
    }

    .approval-drivers-list__item + .approval-drivers-list__item {
        border-top: 1px solid #d6deeb;
    }
}

.insurance-code99 {
    padding: 7px 12px;
    float: right;
    vertical-align: middle;
    font-size: 12px;

    &.mat-mdc-checkbox-checked {
        .mat-mdc-checkbox-background {
            background-color: $error;
        }
    }
}

.cdk-overlay-pane{
    min-width: fit-content;
}