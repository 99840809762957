.tariff-corporate-label {
    /* &--draft {
    } */
    &--complete {
        color: #fbcb00;
    }

    &--approved {
        color: $success;
    }

    &--deployed {
        color: $primary;
    }

    &--deleted {
        color: darken($error, 5%);
        border-color: darken($error, 5%);
    }
}

.corporate-add-selection {
    &__item {
        position: relative;
        border-bottom: 1px solid lighten(#e7ecf3, 5%);

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: lighten(#e7ecf3, 5%);
        }
    }

    &__link {
        display: block;
        padding: 20px 10px;
        padding-right: 40px;
    }

    &__title {
        font-size: 20px;
    }

    &__details {
        font-size: 12px;
        line-height: 20px;
        padding: 5px 0;
        color: #aaa;
    }

    &__chevron {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}

.corporate-groups {
    position: relative;

    &__heading {
        display: inline-block;
        margin-top: 0;
    }

    &__no-results {
        padding: 20px 0;
        color: #aaa;
    }

    &__group-name {
        flex-grow: 1;
    }
}

.corporate-groups__group-link,
.corporate-group-details {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.corporate-group-rates {
    &__table {
        font-size: 12px;
        width: 100%;

        thead {
            border: none;
            background: #f6f6f6;
        }

        tr {
            width: 100%;
        }

        th {
            padding: 1em;
            border: 1px solid #f6f6f6;
        }

        td {
            padding: 0.5em 1em;
            border: 1px solid #f5f5f5;
        }
    }
}

.tariff-corporate-search {
    overflow-y: auto;
    max-width: 280px;
    max-height: 130px;
    border: 1px solid #f9f9f9;
    border-top: 0;

    &__search-item {
        font-size: 12px;
        padding: 5px 7px;
        cursor: pointer;
        border-bottom: 1px solid #f9f9f9;

        &:hover {
            background: #e7f1ff;
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}

.corporate-group-search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__input {
        margin-top: 10px;
    }
}

.tariff-corporate-selected {
    font-size: 12px;
    padding: 7px;
    border: 1px solid #eee;

    &__sub {
        font-size: 10px;
        margin: 2px 0;
        color: #b7b7b7;
    }

    &__actions {
        text-align: right;
    }
}

.tariff-corporate-pricing-form {
    background: #f9f9f9;
}
