.admin-users {
    padding-right: 0;
    border-right: 1px solid #e7ecf3;

    .admin-users__list {
        min-width: 290px;
        padding-right: 0;
        border-right: 1px solid #e7ecf3;
    }

    &__search {
        margin: 0 20px 20px;
        padding: 30px 10px 20px;
        border-bottom: 1px solid #e7ecf3;
    }
}

.admin-user-list {
    min-height: 500px;

    &__user {
        font-size: 12px;
        line-height: 32px;
        color: $link_color;
    }

    &__link {
        display: block;
        padding: 10px 30px;

        &--selected {
            background: #f8f9fc;
        }
    }

    &__user-name {
        line-height: 32px;
        display: inline-block;
    }

    &__user-icon {
        height: 32px;
        margin-right: 10px;
        vertical-align: middle;
        border-radius: 50%;
    }
}

.admin-user {
    padding: 20px;
}

.user-profile {
    margin-top: 40px;
}

.user-profile-details {
    min-height: 400px;
    background: #fff;
}

.user-profile-user {
    color: #fff;
    background: $link_color;

    &__icon {
        float: left;
        padding: 20px;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }

    &__details {
        padding: 20px 10px;
    }

    &__name {
        margin: 0;
    }

    &__position {
        font-size: 12px;
        margin-top: 5px;
        color: #f3f3f3;
    }
}

.user-permissions-table,
.user-permissions-table__permission {
    width: 100%;
}

.user-permissions-table {
    @extend .content-card-table;
}
