@import 'platform-ui/styles/public';

$corporate_list_border: lighten(#e7ecf3, 5%);

/* .corporate-list {

} */

.corporate-list-item {
    &__status-label {
        /* &.corporate-list-item__status-label--draft {
        } */
        &.corporate-list-item__status-label--complete {
            background: #fbcb00;
        }

        &.corporate-list-item__status-label--approved {
            color: #fff;
            background: $success;
        }

        &.corporate-list-item__status-label--deployed {
            color: #fff;
            background: $primary;
        }
    }

    &__btns {
        width: 150px;
        padding: 5px 10px;
        text-align: right;
        align-self: center;
    }

    &__details {
        width: 80%;
        padding: 15px 20px;
    }

    &__name {
        margin: 0;
        font-weight: font-weight();
    }

    &__sub-details {
        font-size: 12px;
        margin-top: 5px;
        color: #aaa;
    }
}

.corporate-list-sub-details {
    &__list {
        @extend .list-horizontal;
    }

    &__item {
        padding: 0 10px;
        border-left: 1px solid #e6e6e6;

        &:first-child {
            padding-left: 0;
            border-left: 0;
        }
    }
}

/* .corporate-list-toolbar {
} */

.corporate-rates-form {
    overflow-x: auto;
}

.corporate-rates-table {
    min-width: 800px;
    background: #fff;

    &__group {
        border-bottom: 1px solid #e0e0e0;
    }

    &__group-details {
        font-size: 12px;
        padding: 0 1em;
    }

    &__row-labels {
        font-size: 14px;
        line-height: 38px;
        padding: 0;
        border: 1px solid #e0e0e0;

        div {
            height: 38px;
            padding: 0 10px;
        }

        div:last-child {
            border-top: 1px solid #e0e0e0;
        }
    }

    &__length {
        padding: 0.5em 0.2em;
        font-size: 13px;
    }

    &__rates {
        padding: 0;
        border: 1px solid #e0e0e0;

        input {
            border-width: 0;
            border-radius: 0;
        }

        div:last-child {
            input {
                border-top-width: 1px;
            }
        }
    }
}
