@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
    $multiplier: 1;
    $current-width: 0;

    max-width: 100%;
    flex-grow: 1;
    flex-basis: $flex-basis;

    @while $current-width < $max-viewport-width {
        $current-width: $current-width + $flex-basis;
        $multiplier: $multiplier + 1;

        @media (min-width: ($flex-basis * $multiplier)) {
            max-width: percentage(1 / $multiplier);
        }
    }
}

.tariff-pricing {
    padding: 25px;
    border: 1px solid #e4e8f2;
    background: $white;
    border-radius: 5px;

    &__corp-account {
        @include break(tablet) {
            margin-top: 93px;
        }
    }
}

.categories-treeview .form-inline {
    display: flex;
}

.tariff-group-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .tariff-group-container {
        display: flex;

        @include flex-wrap-fix(300px);
    }

    .tariff-group {
        width: 100%;
        transition: box-shadow 0.4s;
    }

    &.tariff-group-list--sidebyside {
        .tariff-group {
            a {
                display: flex;
                height: 100%;
            }

            .tariff-group__details {
                width: 66.6666%;
                padding-top: 20px;
            }

            .tariff-group__image-container {
                display: flex;
                flex: 1;
            }

            .tariff-group__image {
                padding-top: 0;
                box-shadow: inset -3px 0 5px -2px rgba(0, 0, 0, 0.1);
                flex: 1;
            }
        }
    }

    &.tariff-group-list--noimage {
        .tariff-group {
            .tariff-group__image {
                display: none;
            }

            .tariff-group-modifiers {
                position: static;
            }
        }
    }

    &.tariff-group-list--rates {
        .tariff-group-container {
            @include flex-wrap-fix(375px, 1500px);
        }

        .tariff-group {
            display: flex;
            flex-direction: column;

            > a {
                flex-grow: 1;
            }
        }

        &.tariff-group-list__rates-corporate {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;
        }
    }
}

.tariff-group-list.tariff-group-list__comparison {
    .tariff-group-container {
        max-width: 100%;

        @include break(tablet) {
            max-width: 50%;
        }
    }
}

.tariff-group-list-views {
    padding: 0 10px;

    > li {
        padding: 5px;
        cursor: pointer;
        color: #888;

        > i {
            font-size: 24px;
        }

        &.is-selected {
            color: #333;
        }
    }
}

.tariff-group-search {
    &__list {
        @extend .list-unstyled;

        overflow: auto;
        max-height: 400px;
        margin: 10px 0;
    }

    &__group {
        margin: 0;
        padding: 11px 0;
        cursor: default;
        border-width: 0;
        border-bottom-width: 1px;

        &-check {
            font-size: 18px;
            position: absolute;
            top: 50%;
            right: 10px;
            padding: 6px 5px;
            cursor: pointer;
            transition: all 0.2s;
            transform: translateY(-50%);
            text-align: center;
            color: #fff;
            border-radius: 5px;
            background: #f1f1f1;

            &:hover {
                background: darken(#f1f1f1, 8%);
            }

            &.tariff-group-search__group-check--checked {
                color: #fff;
                background: $success;

                &:hover {
                    background: darken($success, 8%);
                }
            }

            &.tariff-group-search__group-check--unchecked {
                &:hover {
                    color: #fff;
                    background: $error;
                }
            }
        }

        .tariff-group__details {
            padding: 0 50px 0 0;
        }

        &.is-selected {
            opacity: 0.5;

            .tariff-group--check {
                display: none;
            }
        }
    }
}
