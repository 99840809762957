.simple-scroller {
    &::-webkit-scrollbar:horizontal {
        height: 8px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        transition: all 0.3s;
        border-radius: 2px;
        background-color: lighten(#c1c1c1, 10%);
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
        }
    }
}

.micro-scroller {
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #eee;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:window-inactive {
        background: #ccc;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #aaa;
    }

    &::-webkit-scrollbar-thumb:active {
        background: #888;
    }
}
