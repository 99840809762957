@import 'platform-ui/styles/common';

/**
 * Styles to handle miss match of styles between platform-ui and original styles.
 */

.card + activities-short-list {
    display: block;
    margin-top: spacing();
}
