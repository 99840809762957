.loader {
    margin: 0 auto 1em;
    padding: 1em;
    text-align: center;
    vertical-align: top;

    .loader__spinner {
        position: static;
        display: inline-block;
        transition: 350ms linear all;
        pointer-events: none;
    }

    .loader__spinner-icon {
        width: 20px;
        height: 20px;
        animation: loading-bar-spinner 600ms linear infinite;
        border: solid 3px transparent;
        border-top-color: $link_color;
        border-left-color: $link_color;
        border-radius: 50%;
    }

    .loader__label {
        font-size: 0.7em;
        display: block;
        margin-top: 0.7em;
        // color: $primary;
    }

    &.loader--inline {
        display: inline-block;
        margin: 0;
        padding: 0.75em 1em;

        .loader__label {
            display: none;
        }
    }

    &.loader--dark {
        .loader__spinner-icon {
            border-top-color: #2b2b2b;
            border-left-color: #2b2b2b;
        }
    }
}

.btn {
    .loader {
        padding: 0;
        vertical-align: middle;
    }

    .loader {
        &,
        .loader__spinner,
        .loader__spinner-icon {
            height: 1em;
            width: 1em;
        }
    }
}

ng-progress .ng-progress-bar {
    .is-debug & {
        top: 55px;
    }

    @include break(tablet) {
        left: 200px;
    }
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
