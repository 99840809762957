@mixin layout-component {
    .layout__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        margin-top: spacing(large) * -1;
        margin-left: spacing(large) * -1;
    }

    .layout__top-wrapper {
        margin-bottom: spacing(large);
    }

    .layout__section {
        display: block;
        margin-top: spacing(large);
        margin-left: spacing(large);
        max-width: calc(100% - spacing(large));
    }

    .layout__section {
        flex: 2 2 layout-width(primary, min);
        min-width: 51%;
    }

    .layout__section--secondary {
        flex: 1 1 layout-width(secondary, min);
        min-width: 0;
        max-width: layout-width(secondary, max);
    }

    .layout__section--half {
        flex: 1 1 layout-width(one-half-width);
        min-width: 0;
    }

    .layout__section--third {
        flex: 1 1 layout-width(one-third-width);
        min-width: 0;
    }
}
