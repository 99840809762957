@import 'platform-ui/styles/public';

.tariff-group {
    position: relative;
    margin: 10px;
    border: 1px solid #e4e8f2;
    background: $white;
    border-radius: 5px;
    overflow: hidden;

    &.tariff-group--mini {
        display: flex;
        min-height: 125px;
        flex-direction: column;

        .tariff-group__image {
            min-height: 150px;
            padding: 0;
        }

        @include break(tablet) {
            flex-direction: row;

            .tariff-group__image {
                width: 250px;
                height: 100%;
            }

            .tariff-group__details {
                align-self: center;
            }
        }
    }

    &.tariff-group--clickable {
        cursor: pointer;
        transition: box-shadow 0.4s;

        &:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08),
                0 6px 6px rgba(0, 0, 0, 0.12);
            //box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
        }
    }

    .tariff-specifications {
        padding: 5px 10px;
    }

    .tariff-specification,
    .tariff-specifications__show-all {
        font-size: 12px;
    }

    .tariff-specification__no-result {
        font-size: 12px;
        padding: 10px 5px;
    }
}

.tariff-group__make {
    line-height: 1;
    margin: 0;
    padding: 7px;
    font-weight: font-weight();
}

.tariff-group__model {
    // font-size: font-size();
    // line-height: line-height();
    margin: 0 0 4px;
    padding: 0 7px 7px;
    font-weight: font-weight();

    // @include content-when-medium() {
    //     font-size: font-size(small);
    //     line-height: line-height(small);
    // }
}

.tariff-group__group {
    font-size: 10px;
    font-weight: 700;
    margin-right: 4px;
    color: $labs_blue;
}

.tariff-group__details {
    flex-grow: 1;
}

.tariff-group__image-container {
    background-color: #f5f5f5;
    position: relative;
    display: block;

    &:after {
        font-size: 10px;
        position: absolute;
        top: 55%;
        left: 50%;
        display: block;
        content: 'No Image Found';
        transform: translate(-50%, -50%);
        text-align: center;
        color: #bababa;
    }
}

.tariff-group__image {
    position: relative;
    width: 100%;
    padding-top: 66%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 2;

    // Add inner image so the user can still right click to save.
    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}

.tariff-group__image-caption {
    font-size: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5px 7px;
}

.tariff-group__image--add-image {
    cursor: pointer;
    background: #f5f5f5;
    background-size: auto;

    &:before {
        font-size: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: '+';
        transform: translate(-50%, -60%);
        color: #bababa;
    }

    &:after {
        font-size: 10px;
        position: absolute;
        top: 60%;
        left: 50%;
        display: block;
        content: 'Add Image';
        transform: translate(-50%, 0%);
        color: #bababa;
    }

    &:hover {
        background: #e8e8e8;
    }
}

.tariff-group__image-remove,
.tariff-group__image-main {
    font-size: 10px;
    position: absolute;
    top: 5px;
    display: block;
    padding: 3px 5px;
    cursor: pointer;
    transition: background 0.3s;
    color: #555;
    background: #f5f5f5;
}

.tariff-group__image-remove {
    right: 0;
    color: #fff;
    background: #bf171c;
}

.tariff-group__image-main.is-selected {
    color: white;
    background: #188435;
}

.tariff-group__detail {
    @extend %clearfix;

    padding: 7px 10px;
    border-top: 1px solid #eee;

    .tariff-group__detail-content,
    .tariff-group__detail-sub-content {
        display: inline-block;
        vertical-align: baseline;
    }

    .tariff-group__detail-content {
        margin-right: 2px;
    }

    .tariff-group__detail-comparison {
        font-size: 0.7em;
        display: inline-block;
        padding: 0 5px;
    }

    .tariff-group__detail-sub-content {
        font-size: 0.7em;
        margin-left: 4px;
    }

    .tariff-group__detail-label {
        float: right;
    }
}

.tariff-group__detail-link {
    transition-duration: 0.5s;

    &:hover {
        color: #999;
    }
}

.tariff-group__image-link {
    transition-duration: 0.5s;

    &:hover {
        opacity: 0.7;
    }
}

.tariff-group__pricing {
    .tariff-group__pricing-price--retail {
        padding-left: 7px;
    }

    .tariff-group__pricing-price--discount {
        padding-left: 7px;
    }
}

.tariff-group__insurance-increase {
    // text-align: right;
    // flex-grow: 1;

    input {
        width: 70px;
        height: 25px;
        padding: 0 5px 0 2px;
    }
}

.tariff-group__pricing-price__standard {
    color: #a3a3a3;
    background: #f9f9f9;
}

.tariff-group__detail-discount-label {
    margin-left: 3px;
    color: $warm;
}

.tariff-group--message-coi {
    font-size: 12px;
    padding: 8px 12px;
    background: $pail_yellow;
}

.tariff-group__heading {
    font-size: 14px;
    display: inline-block;
    padding: 5px 15px;
    background: #f1f1f1;
}

.tariff-group__heading--pricing {
    color: #fff;
    background: #188435;
}

.tariff-group__heading--spec {
    color: $white;
    background: $info;
}

.tariff-group--insurance {
    .tariff-group--insurance-input {
        select {
            border-width: 0;
            border-top-width: 1px;
        }
    }
}

.tariff-group-modifiers {
    font-size: 0.7em;
    display: flex;
    flex-wrap: wrap;

    &.tariff-group-modifiers--top {
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
    }

    .tariff-group-modifier {
        padding: 5px 10px;
        background: #fff;
        border: 1px solid;
    }

    .tariff-group-modifier--clickable {
        cursor: pointer;
        transition: box-shadow 0.4s;

        &:hover {
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
        }
    }

    .tariff-group-modifier--error {
        color: #fff;
        background: $error;
        border-color: $error;
    }

    .tariff-group-modifier--warn {
        color: #fff;
        background: $warm;
        border-color: $warm;
    }

    .tariff-group-modifier--rule {
        color: #fff;
        background: $info;
        border-color: darken($info, 5%);
    }

    .tariff-group-modifier--insurance {
        span {
            display: none;
        }

        &:hover {
            span {
                display: inline;
            }
        }
    }

    .tariff-group-modifier--insurance-icon {
        margin-left: 5px;
    }

    .tariff-group-modifier--insurance-coi {
        border-color: #eee;
    }

    .tariff-group-modifier--insurance-standard {
        background: #ebf1de;
        border-color: #ebf1de;
    }

    .tariff-group-modifier--insurance-softrestricted {
        background: #fcd5b4;
        border-color: #fcd5b4;
    }

    .tariff-group-modifier--insurance-hardrestricted {
        background: #e6b8b7;
        border-color: #e6b8b7;
    }
}

.tariff-group-selection {
    position: relative;

    .tariff-group-selection__group-clear {
        font-size: 0.8em;
        position: absolute;
        top: 1px;
        right: 1px;
        padding: 8px;
        transition: all 0.3s;

        &:hover {
            background: #fafafa;
        }
    }

    .tariff-group-selection__groups {
        margin-bottom: 10px;
    }

    .tariff-group-selection__group {
        .tariff-group {
            margin: 0;
        }

        @include break(tablet) {
            .tariff-group {
                display: flex;
                min-height: 125px;
            }

            .tariff-group__image {
                width: 250px;
                padding-top: 0;
            }

            .tariff-group__details {
                align-self: center;
            }
        }
    }
}

.tariff-group-selection-search {
    .dropdown {
        float: right;
        margin-top: 3px;
    }

    .dropdown__toggle {
        display: inline-block;
        padding: 10px 5px;
    }

    .dropdown__list {
        right: 0;
        left: auto;
    }
}
