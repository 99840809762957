.not-found-wrapper {
    font-weight: 400;
    line-height: 1.5em;
    padding: 150px 0;
    text-align: center;
    color: #333;
}

.not-found-wrapper__message {
    max-width: 342px;
    margin: 0 auto 40px;
}

.not-found-wrapper__btns {
    display: flex;
    max-width: 342px;
    margin: 0 auto;
    justify-content: space-between;
}

.not-found-wrapper__heading {
    position: relative;
    display: inline-block;
    margin-bottom: 28px;
    animation-delay: 5s;
}
