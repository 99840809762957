.bulk-discount-form {
    margin: 0 0 21px;
}

/* .bulk-discount-type {
} */

.discount-table {
    font-size: 0.8em;
    margin: 14px 0;
}
