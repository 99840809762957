@mixin baseCol {
    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
    min-height: 1px;
    min-width: 0;
}

%basecol {
    @include baseCol;
}

%colnogutter {
    padding-right: 0;
    padding-left: 0;
}

$grid-breakpoints-map: (
    '': null,
    'm-': tablet,
    'l-': large-tablet,
    'xl-': desktop,
);

$min-grid-breakpoints-map: (
    '': 390px,
    'm-': 768px,
    'l-': 950px,
    'xl-': 1025px,
);

$grid-map: (
    '1': 10,
    '2': 20,
    '3': 30,
    '4': 40,
    '5': 50,
    '6': 60,
    '7': 70,
    '8': 80,
    '9': 90,
    '10': 100,
);

$grid-custom-map: (
    'sixth': 16.666666,
    'quarter': 25,
    'third': 33.333333,
    '4-5': 45,
    'half': 50,
    'two-thirds': 66.666666,
    'three-quarters': 75,
    'full': 100,
);

@mixin generateBaseCols() {
    @each $prefix, $media in $grid-breakpoints-map {
        .#{$prefix}col {
            @extend %basecol;
        }

        .row--no-gutter > .#{$prefix}col {
            @extend %colnogutter;
        }

        .#{$prefix}col-auto {
            @extend %basecol;
        }

        @each $name, $size in $grid-map {
            .#{$prefix}col-#{$name} {
                @extend %basecol;
            }
        }

        @each $name, $size in $grid-custom-map {
            .#{$prefix}col-#{$name} {
                @extend %basecol;
            }
        }

        @if $media != null {
            @include break($media) {
                @include generateColSizes($prefix);
            }
        } @else {
            @include generateColSizes();
        }
    }
}

/**
* Generate the column sizes and offsets with a given prefix.
*/
@mixin generateColSizes($prefix: '') {
    .#{$prefix}col-break {
        flex-basis: 100%;
        height: 0;
    }

    .#{$prefix}row--align-center {
        align-items: center;
    }

    .#{$prefix}row--justify-center {
        justify-content: center;
    }

    .#{$prefix}col {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .#{$prefix}col-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .#{$prefix}col--center {
        align-self: center;
    }

    .#{$prefix}col--no-padding {
        padding-right: 0;
        padding-left: 0;
    }

    @each $name, $size in $grid-map {
        .#{$prefix}col-#{$name} {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 $size * 1%;
            max-width: $size * 1%;
        }

        @if $size < 100 {
            .#{$prefix}push-right-#{$name} {
                margin-left: $size * 1%;
            }

            .#{$prefix}push-left-#{$name} {
                margin-right: $size * 1%;
            }
        }
    }

    @each $name, $size in $grid-custom-map {
        .#{$prefix}col-#{$name} {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 $size * 1%;
            max-width: $size * 1%;
            // width: $size * 1%;
        }
    }
}

.grid {
    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -14px;
    margin-left: -14px;
}

.row--no-gutter {
    margin-right: 0;
    margin-left: 0;

    > [class*='col-'] {
        @extend %colnogutter;
    }

    .col-stretch {
        flex-grow: 1;
    }
}

@include generateBaseCols();

@each $prefix, $media in $min-grid-breakpoints-map {
    @media screen and (max-width: $media) {
        .#{$prefix}col-break-o {
            flex-basis: 100%;
            height: 0;
        }
    }
}
