.badge {
        // background: rgba(142, 147, 156, 0.31);
        // margin-left: 8px;
    // font-size: 0.8em;
    // line-height: 1;
    // text-align: center;
    // border-radius: 2px;
    // vertical-align: baseline;
    // text-transform: capitalize;
    // display: inline-block;
    //
    // padding: 2px 6px;
    // top: -1px;
    // font-weight: 700;
    // position: relative;

    &.badge--right {
        float: right;
    }

    &.badge--primary {
        color: $primary;
        background: rgba(181, 186, 204, 0.33);
    }

    &.badge--warm {
        color: $warm;
        background: rgba(255, 153, 0, 0.1);
    }

    &.badge--info {
        color: $info;
        background: scale-color($info, $alpha: -90%);
    }

    &.badge--dark {
        background: $black;
        color: $accent_grey;
    }

    &.badge--success {
        color: $success;
        background: rgba(39, 174, 96, 0.1);
    }

    &.badge--error {
        color: $error;
        background: rgba(230, 0, 0, 0.1);
    }

    &.badge--inverted {
        background-color: $body_color;
    }
}

.btn > .badge {
    margin-left: 4px;
    position: relative;
    top: -3px;
    margin-bottom: -6px;
}
