@mixin spinner-component {
    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner .spinner__icon {
        width: rem(36px);
        height: rem(36px);
        animation: loading-bar-spinner 800ms linear infinite;
        border: border-width(thicker) solid transparent;
        border-top-color: currentColor;
        border-radius: border-radius(full);
        display: inline-block;
        color: var(--foreground-link);

        &:after {
            content: '';
            position: absolute;
            box-sizing: content-box;
            transform-origin: 0% 0%;
            opacity: 0.3;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translateZ(0px) translateX(-50%) translateY(-50%);
            z-index: 200;
            overflow: hidden;
            border-radius: border-radius(full);
            border: border-width(thicker) solid currentColor;
            border-image: initial;
        }

        &.spinner--inverted {
            color: var(--foreground-inverted);
        }
    }

    .spinner--s-small .spinner__icon {
        width: rem(24px);
        height: rem(24px);
    }

    .spinner--s-large .spinner__icon {
        width: rem(52px);
        height: rem(52px);
    }

    .spinner--s-x-large .spinner__icon {
        width: rem(80px);
        height: rem(80px);

        &,
        &:after {
            border-width: border-width(thickest);
        }
    }

    .spinner--c-current .spinner__icon {
        color: inherit;
    }

    // TODO move out into global.
    @keyframes loading-bar-spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
