@mixin headings-base {
    h1 {
        font-size: font-size(xx-large);
        font-weight: font-weight(semi-bold);
        line-height: line-height(xx-large);
    }

    h2 {
        font-size: font-size(x-large);
        font-weight: font-weight(semi-bold);
        line-height: line-height(x-large);
    }

    h3 {
        font-size: font-size(large);
        font-weight: font-weight(semi-bold);
        line-height: line-height(large);
    }

    h4 {
        font-size: font-size();
        font-weight: font-weight(semi-bold);
        line-height: line-height();
    }

    h5 {
        font-size: font-size(small);
        font-weight: font-weight(semi-bold);
        line-height: line-height(small);
        text-transform: uppercase;
    }
}
