@mixin page-header-component {
    .page-header {
        display: block;

        @include layout-spaced();

        @include content-when-large() {
            padding-top: spacing(x-large);
        }
    }

    .page-header__wrapper {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        @include content-when-small() {
            flex-direction: row;
        }
    }

    .page-header__title-wrapper {
        flex: 1 auto;
        width: 100%;
        position: relative;
    }

    .page-header__primary-title {
        flex: 1 auto;
    }

    .page-header__title {
        display: inline;
        font-size: font-size(x-large);
        line-height: line-height(x-large);
    }

    .page-header__title-extras {
        display: inline-block;
    }

    .page-header__sub-title {
        margin-top: spacing(xx-small);
    }

    .page-header__primary-actions {
        margin-top: spacing(small);
    }

    .page-header__secondary-actions {
        margin-top: spacing(x-small);
    }

    @include content-when-medium() {
        .page-header__wrapper {
            flex-direction: row;
            align-items: center;
        }

        .page-header__primary-actions {
            margin-top: 0;
        }

        .page-header__secondary-actions {
            margin-top: spacing(small);
        }
    }
}
