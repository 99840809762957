.list-unstyled {
    padding: 0;
    list-style: none;
}

.list-flex {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
}
.list-flex__spacer {
    flex-grow: 1;
    min-height: 1px;
}

.list-flex--no-wrap {
    flex-wrap: nowrap;
}

.list-flex--align-center {
    align-items: center;
}

.list-flex--align-baseline {
    align-items: baseline;
}

.list-flex--grow {
    flex-grow: 1;
}

.list-flex--justify-end {
    justify-content: flex-end;
}

.list-flex--grow {
    flex-grow: 1;
}

.list-flex--spaced {
    > * {
        margin: 0 8px 0 0;
    }

    > :last-child {
        margin: 0;
    }
}

.list-flex--nowrap {
    flex-wrap: nowrap;
}

.list-horizontal {
    padding: 0;
    list-style: none;

    &:after,
    &:before {
        display: table;
        content: '';
    }

    &:after {
        clear: both;
    }

    > li {
        float: left;

        &.list--item__right {
            float: right;
        }
    }
}

.category-select {
    overflow-y: auto;
    max-height: 200px;
    margin: 10px 0;

    .ivh-treeview {
        font-size: 12px;
        line-height: 22px;
        list-style: none;

        ul {
            padding-left: 15px;
            list-style: none;
        }

        .ivh-treeview-checkbox {
            cursor: pointer;
        }
    }
}

.list-styled {
    padding: 0;
    list-style: none;

    .list-styled__item {
        position: relative;

        &:last-child {
            .list-styled__link,
            .list-styled__label {
                border-bottom: 0;
            }
        }
    }

    .list-styled__link,
    .list-styled__label {
        // font-size: 0.8em;
        display: block;
        margin: 0;
        padding: 16px 7px;
        transition: background 0.2s;
        border-bottom: 1px solid #d6deeb;
    }

    .list-styled__link {
        cursor: pointer;

        &:hover,
        &:focus {
            background: #fafafa;

            .list-styled__action--label {
                opacity: 1;
            }
        }

        &.list-styled__link--disabled {
            cursor: not-allowed;
            opacity: 0.5;
            background: transparent;
        }

        &.list-styled__link--selected {
            // cursor: not-allowed;
            // opacity: 0.5;
            // background: transparent;
            background: var(--background-button);
        }
    }

    .list-styled__actions {
        @extend .list-horizontal;

        font-size: 0.8em;
        position: absolute;
        top: 17px;
        right: 10px;
    }

    .list-styled__action {
        margin: 0 3px;
    }

    .list-styled__action--label {
        opacity: 0;
        transition: opacity 0.4s;
    }

    .list-styled__desc {
        font-size: 0.8em;
        margin-top: 0.2em;
    }
}
