@use '@angular/material' as mat;
@include mat.core();

// @import '@angular/material/theming';
@import 'platform-ui/styles/public';

/**
 * DEPRECATED use css-vars, see the platform-ui library
 * [docs](https://county-labs-v5.azurewebsites.net/storybook).
 */

/**
 * Colours
 */
$white: #fff !default;
$black: #212121 !default;
$labs_blue: #496392 !default;
$dark_labs_blue: desaturate($labs_blue, 20%);
$county_blue: #253477 !default;

$default: #f9f9f9;
$primary: #515874;
$success: #27ae60;
$info: #5092da;
$error: #e60000;
$warm: #f90;
$extra: #a366c2;
$pail_yellow: #ffc;

$link_color: lighten(#65c5ff, 1%);
$body_bg_color: lighten(#526fa3, 45%);
$body_color: #47464c;
$accent_grey: #f5f5f5;

/**
 * Buttons
 */
$btn-font-size: rem(15px);
$btn-line-height: rem(18px);
$btn-vertical-padding: rem(10px);
$btn-horizontal-padding: rem(14px);
$btn-background-color: #fafbfc;
$btn-color: #778d99;
$btn-border-color: #cedbdf;
$btn-border-width: 1px;
$btn-border-radius: 3px;

/**
 * Forms
 */
$inputs: 'input[type=text], \
    input[type=email], \
    input[type=number], \
    input[type=date], \
    input[type=search], \
    .input';
$input-height: 38px;
$input-width: 280px;

$input-error-color: var(--border-error);
$input-background-color: #fafbfc;
$input-border-color: #cedbdf;
$input-border-width: 1px;
$input-focus-color: #5092da;
$input-border-radius: 3px;
$input-line-height: rem(18px);

@function my-mat-light-theme-foreground() {
    @return (divider: $accent_grey, dividers: $accent_grey);
}

@function my-mat-light-theme-background() {
    @return (background: $body_bg_color);
}

$my-app-blue: (
    50: #ffffff,
    100: #ecf3fb,
    200: #bdd6f1,
    300: #83b1e5,
    400: #69a2df,
    500: #5092da,
    600: #3782d5,
    700: #2973c3,
    800: #2464aa,
    900: #1f5591,
    A100: #ffffff,
    A200: #c5e0fe,
    A400: #6eacef,
    A700: #609fe3,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// Define the theme.
$my-app-primary: mat.define-palette(mat.$indigo-palette);
$my-app-accent: mat.define-palette($my-app-blue, 500, 400, 700);
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
$my-app-foreground: map-merge(
    map-get($my-app-theme, foreground),
    my-mat-light-theme-foreground()
);
$my-app-background: map-merge(
    map-get($my-app-theme, background),
    my-mat-light-theme-background()
);

$my-app-theme-custom: map-merge(
    $my-app-theme,
    (
        foreground: $my-app-foreground,
        background: $my-app-background,
    )
);
