@import '../variables/colors';
@import 'platform-ui/styles/public';

.site-menu {
    flex-grow: 1;
}

.site-page__content {
    @include break(tablet) {
        flex: 1 1 auto;
    }
}

.site-logo {
    float: left;
    height: 30px;
    margin: 15px 20px;
}

.content-section__header {
    display: flex;

    .content-section__heading {
        flex: 1;
    }

    .content-section__actions {
        margin: rem(16px) rem(22px) 0;
    }
}

.content-section__heading {
    font-size: 1.5em;
    margin: rem(16px) rem(22px) rem(26px);
}

.content-section--sidebar {
    display: flex;
    flex-direction: column;

    .content-section__sidebar {
        order: -1;
    }

    @include break(tablet) {
        flex-direction: row;

        .content-section__body {
            flex: 1;
            overflow: hidden;
        }

        .content-section__sidebar {
            flex: 0 0 275px;
        }
    }
}

.content-sidebar {
    @include break(tablet) {
        width: 275px;
        padding: 0 25px 0 0;
    }
}

.content-sidebar__divider {
    margin: 1.3em 0;
    height: 1px;
    background: #d5dae0;
}

.content-sidebar__list-item {
    margin-bottom: 7px;
}

.content-sidebar__list-item-link {
    padding: 0.8em 1.3em;
    border-radius: 3px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    transition: background 0.3s;
}

.content-sidebar__list-item-icon {
    margin-right: 0.6em;
    color: rgb(197, 204, 206);
}

.content-sidebar__list-item-label,
.content-sidebar__list-item-icon {
    transition: color 0.3s;
}

.content-sidebar__list-item-label {
    flex: 1;
    color: #4d4e50;
}

.content-sidebar__list-item-link[disabled] {
    opacity: 0.6;
}

.content-sidebar__list-item-link:not([disabled]):hover {
    .content-sidebar__list-item-label,
    .content-sidebar__list-item-icon {
        color: $info;
    }
}

.content-sidebar__list-item-link.content-sidebar__list-item-link--selected {
    background: #f5f7fa;
    .content-sidebar__list-item-label,
    .content-sidebar__list-item-icon {
        color: #333;
    }
}

.content-breadcrumbs {
    font-size: 12px;
    line-height: 60px;
    overflow: hidden;
    padding: 0 10px;
    list-style: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #7789ad;

    .content-breadcrumbs__breadcrumb {
        font-size: 0.75em;
        display: inline-block;
        padding: 0 10px 0 0;
    }

    .content-breadcrumbs__breadcrumb + .content-breadcrumbs__breadcrumb:before {
        font-size: 0.9em;
        padding-right: 10px;
        content: '/';
        color: #333;
    }

    .content-breadcrumbs__breadcrumb:last-of-type {
        font-size: 1em;
        color: #333;
    }

    @include break(tablet) {
        font-size: 16px;
    }
}

.content-header-container {
    background: #fff;
}

.content-header {
    display: flex;
    padding: 35px 0;
    flex-wrap: wrap;

    &__headings {
        // flex-grow: 1;
    }

    &__sub-header {
        font-size: 16px;
        margin-top: 4px;
        color: lighten($body_color, 5%);
    }

    h1 {
        font-size: 30px;
        font-weight: normal;
        margin: 0;
    }
}

.content-actions {
    flex-grow: 1;

    .content-actions__status-label {
        align-self: center;
        justify-self: flex-start;
        margin-right: auto;

        // Styles fron .btn
        font-size: $btn-font-size;
        line-height: $btn-line-height;
        color: $btn-color;
        background: $default;
        padding: $btn-vertical-padding $btn-horizontal-padding;
        border-radius: $btn-border-radius;
    }
}

.status-label {
    .status-label__dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 6px;
        margin-left: -2px;
        border-radius: 10px;
        background: #d6deeb;
    }

    &.status-label--primary {
        color: $primary;

        .status-label__dot {
            background: $primary;
        }
    }

    &.status-label--success {
        color: $success;

        .status-label__dot {
            background: $success;
        }
    }

    &.status-label--warn {
        color: $warm;

        .status-label__dot {
            background: $warm;
        }
    }

    &.status-label--info {
        color: $info;

        .status-label__dot {
            background: $info;
        }
    }

    &.status-label--error {
        color: $error;

        .status-label__dot {
            background: $error;
        }
    }

    &.status-label--black {
        color: $black;

        .status-label__dot {
            background: $black;
        }
    }
}

.content-section {
    position: relative;
    padding: 40px 0;

    &--inset {
        border-top: 1px solid #d6deeb;
        border-bottom: 1px solid #d6deeb;
        background: #e1e6f0;
    }

    .content-header + & {
        padding-top: 10px;
    }
}

.content-section--split {
    display: flex;
    flex-direction: column;

    .content-section__sidebar {
        margin-bottom: 25px;
    }

    .content-section__sidebar--last {
        order: 1;
    }

    @include break(tablet) {
        flex-direction: row;

        .content-section__sidebar {
            width: 20%;
            max-width: 200px;
            margin: 0 25px 0 0;
        }

        .content-section__body {
            flex: 1;
        }

        .content-section__body + .content-section__sidebar {
            margin: 0 0 0 25px;
        }

        .content-section__sidebar--last {
            order: 0;
        }
    }
}

.content-toolbar-container {
    border-bottom: 1px solid #e2e7e7;
    background: #fff;
}

.content-toolbar {
    list-style: none;

    &__item {
        display: inline-block;
        border-right: 1px solid #e2e7e7;
    }

    &__link {
        font-size: 14px;
        display: block;
        padding: 15px 20px;
        transition: all 0.3s;
        text-align: center;
        color: #767676;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;

        &:hover {
            color: #222;
            background: #fff;
        }

        &.content-toolbar__link--selected {
            background: #fff;
            border-bottom-color: darken($link_color, 3%);
        }

        &.content-toolbar__link--error {
            border-bottom-color: $error;
        }
    }

    &--flex {
        display: flex;

        .content-toolbar__item {
            flex: 1 0 0;
        }
    }

    &--dark {
        border-bottom: 1px solid #e2e7e7;
        background: #f9f9f9;
    }

    .content-toolbar__error:after {
        font-size: 10px;
        line-height: 20px;
        display: inline-block;
        width: 8px;
        height: 8px;
        content: ' ';
        color: transparent;
        border-radius: 50%;
        background: #bf171c;
    }
}

.wrapper {
    width: 100%;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 14px;
    padding-right: 14px;

    &--large {
        max-width: 1500px;
    }

    &--small {
        max-width: 600px;
    }
}

// Remove opacity from tab labels.
.mat-mdc-tab-labels .mat-mdc-tab-label {
    opacity: 1;
}

.setting-item {
    display: flex;

    .setting-item__link {
        flex: 1;
        padding: 1em;
        margin: 1em 0;
        border-radius: 3px;
        display: block;
        transition: background 0.4s;

        &:hover {
            background-color: #fafbfc;
        }
    }

    .setting-item__title {
        margin-top: 0;
    }
}
