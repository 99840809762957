*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    font-size: $font-size;
    line-height: 1.5;

    @include break(tablet) {
        font-size: 14px;
    }
}

body {
    font-family: Arial, Helvetica, Verdana, sans-serif;
    box-sizing: border-box;
    min-height: 100%;
    color: $body_color;
    background: $body_bg_color;
    font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: transparent;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

pre {
    overflow: auto;
    width: 100%;
    max-width: 100%;
}

p {
    margin: 8px 0 0;
}

.print-only {
    display: none !important;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
}

[hidden] {
    display: none !important;
}

@mixin pl-helper-classes($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $primary: map-get($theme, accent);

    .link {
        color: mat.get-color-from-palette($primary, lighter);

        &:hover,
        &--hover {
            text-decoration: underline;
        }
    }

    a:hover > .link {
        text-decoration: underline;
    }

    .sub-text {
        color: mat.get-color-from-palette($foreground, hint-text);
        // font-size: 0.87em;
    }

    .dim-text {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }

    .dim-link {
        color: inherit;
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

        &:hover {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
