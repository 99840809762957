.customer-form__types {
    display: flex;

    .customer-form__type {
        margin: 5px;
        padding: 150px 50px;
        transition: all 0.3s;
        text-align: center;
        background: #f9f9f9;
        flex: 1;

        &:hover {
            color: #fff;
            background: $info;
        }
    }

    .customer-form__type-heading {
        font-size: 26px;
        margin: 0;
    }
}

.customer-form__types--insurance {
    .customer-form__type {
        padding: 77px 59px 63px;
        color: #ecf0f1;
        background: #446cb3;

        &:hover {
            color: #ecf0f1;
            background: #b0c5e0;
        }
    }

    .customer-form__type-image {
        width: 100%;
        max-width: 316px;
        margin-bottom: 35px;
    }
}

.company-people-selection {
    .company-people-selection__list {
        margin-bottom: 14px;
    }
}
