@mixin page-component {
    .page {
        display: block;
        max-width: layout-width(primary, max) + layout-width(secondary, max) +
            layout-width(outer-spacing, max);
        margin: 0 auto;
        padding: 0;

        @include content-when-medium() {
            padding: 0 spacing(large);
        }

        @include content-when-large() {
            padding: 0 spacing(x-large);
        }
    }

    .page__content {
        margin: spacing(large) 0;

        @include content-when-medium() {
            margin: spacing(x-large) 0;
        }
    }

    .page--s-x-narrow {
        max-width: layout-width(primary, min) +
            ((layout-width(primary, max) - layout-width(primary, min)) / 2);
    }

    .page--s-narrow {
        max-width: layout-width(primary, max);
    }

    .page--s-wide {
        max-width: layout-width(primary, wide) + layout-width(secondary, max) +
            layout-width(outer-spacing, max);
    }

    .page--s-full {
        max-width: none;
    }
}
